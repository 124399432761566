import { defineStore } from "pinia";
import { ref } from "vue";

export const useGlobalStore = defineStore("global", () => {
  const pageLoaded = ref(false);
  function pageLoad(progress) {
    document.getElementById("color").style.setProperty("transition", "all 0.1s, visibility 0s");
    document.getElementById("color").style.setProperty("width", "100%");
    setTimeout(function () {
      document.getElementById("color").style.visibility = "hidden";
      document.getElementById("color").style.setProperty("width", "0%");
      setTimeout(function () {
        document.getElementById("color").style.setProperty("transition", "all 1.5s, visibility 0s");
      }, 100);
    }, 150);
    if (!progress) {
      if (document.readyState == "complete") {
        pageLoaded.value = true;
      } else {
        window.addEventListener("load", function () {
          pageLoaded.value = true;
        });
      }
    }
  }
  const volume = ref(100);
  const answering = ref(2);
  const difficulty = ref(1);
  const number = ref(20);
  const type = ref(1);
  const time = ref(20);
  const joker = ref(1);
  const duplicateShow = ref(true);
  const custom = ref(false);
  const privateRoom = ref(false);
  const signIn = ref(false);
  const signUp = ref(false);
  const signPassword = ref(false);
  const animesSuggest = ref([]);
  const animesSuggestIndex = ref(0);
  const back = ref(false);
  const step = ref(0);
  const year = ref([1961, new Date().getFullYear()]);
  const season = ref(0);

  return {
    pageLoad,
    pageLoaded,
    volume,
    signIn,
    signUp,
    signPassword,
    animesSuggest,
    animesSuggestIndex,
    answering,
    difficulty,
    number,
    type,
    time,
    joker,
    duplicateShow,
    custom,
    privateRoom,
    back,
    step,
    year,
    season,
  };
});
