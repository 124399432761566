import { createRouter, createWebHistory } from "vue-router";
import { useJoinRoomStore } from "./stores/joinRoom";
import { useAuthStore } from "@/stores/auth";
import { useCookies } from "@vueuse/integrations/useCookies";
import { useFetch } from "@vueuse/core";
// import HomeGame from "./components/HomeGame.vue";
// import RoomGame from "./components/RoomGame.vue";
// import PageNotFound from "./components/PageNotFound.vue";
// import InfoAnime from "./components/InfoAnime.vue";
// import BattlePass from "./components/BattlePass.vue";
// import AvatarView from "./components/AvatarView.vue";
// import AnimeView from "./components/AnimeView.vue";
// import UserProfile from "./components/UserProfile.vue";
// import ProfileView from "./components/ProfileView.vue";
// import MaintenanceView from "./components/MaintenanceView.vue";
// import TestView from "./components/TestView.vue";
// import TikTokMenu from "./components/TikTokMenu.vue";
// import TikTok from "./components/TikTok.vue";
// import Admin from "./components/AdminMenu.vue";
// import ResetPassword from "./components/ResetPassword.vue";
// import ModoSongManage from "./components/ModoSongManage.vue";
// import AdminAnimeManage from "./components/AdminAnimeManage.vue";
// import PrivacyPolicy from "./components/PrivacyPolicy.vue";
// import SettingsView from "./components/SettingsView.vue";
// import TikTokCharacter from "./components/TikTokCharacter.vue";
// import TikTokEasy from "./components/TikTokEasy.vue";
// import TiktokThumbnail from "./components/TiktokThumbnail.vue";
// import AdminAddSongs from "./components/AdminAddSongs.vue";
// import AdminAddScreenshots from "./components/AdminAddScreenshots.vue";
// import TikTokV2 from "./components/TikTokV2.vue";
// import TiktokPixelAnime from "./components/TiktokPixelAnime.vue";
// import TiktokPixelCharacter from "./components/TiktokPixelCharacter.vue";
// import TiktokRandomCharacter from "./components/TiktokRandomCharacter.vue";
// import TiktokScreenshot from "./components/TiktokScreenshot.vue";
// import TiktokCut from "./components/TiktokCut.vue";
// import TiktokManage from "./components/TiktokManage.vue";
// import TiktokVoice from "./components/TiktokVoice.vue";
// import TiktokPixelVideo from "./components/TiktokPixelVideo.vue";
// import AdminWidget from "./components/AdminWidget.vue";
// import TestRoomGame from "./components/TestRoomGame.vue";
// import TestHome from "./components/TestHome.vue";
// import TestBattlePass from "./components/TestBattlePass.vue";
// import TestStore from "./components/TestStore.vue";
// import TestInfoAnime from "./components/TestInfoAnime.vue";

const routes = [
  { path: "/", component: () => import("./components/HomeGame.vue"), name: "home" },
  { path: "/room", component: () => import("./components/RoomGame.vue"), name: "room" },
  { path: "/testroom", component: () => import("./components/TestRoomGame.vue"), name: "testroom" },
  { path: "/testhome", component: () => import("./components/TestHome.vue"), name: "testhome" },
  { path: "/battle-pass", component: () => import("./components/BattlePass.vue"), name: "battlepass" },
  { path: "/testbattle-pass", component: () => import("./components/TestBattlePass.vue"), name: "testbattlepass" },
  {
    path: "/avatar",
    component: () => import("./components/AvatarView.vue"),
    beforeEnter: checkConnected,
    name: "avatar",
  },
  { path: "/anime", component: () => import("./components/AnimeView.vue"), name: "anime" },
  { path: "/@:username", component: () => import("./components/UserProfile.vue"), name: "user" },
  {
    path: "/profile",
    component: () => import("./components/ProfileView.vue"),
    beforeEnter: checkConnected,
    name: "profile",
  },
  {
    path: "/settings",
    component: () => import("./components/SettingsView.vue"),
    beforeEnter: checkConnected,
    name: "settings",
  },
  { path: "/test", component: () => import("./components/TestView.vue"), beforeEnter: checkAdminRights, name: "test" },
  { path: "/teststore", component: () => import("./components/TestStore.vue"), name: "teststore" },
  {
    path: "/admin/tiktok/pixel/anime",
    component: () => import("./components/TiktokPixelAnime.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokPixelAnime",
  },
  {
    path: "/admin/tiktok/voice",
    component: () => import("./components/TiktokVoice.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokVoice",
  },
  {
    path: "/admin/tiktok/manage",
    component: () => import("./components/TiktokManage.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokManage",
  },
  {
    path: "/admin/widget",
    component: () => import("./components/AdminWidget.vue"),
    name: "AdminWidget",
  },
  {
    path: "/admin/tiktok/cut",
    component: () => import("./components/TiktokCut.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokCut",
  },
  {
    path: "/admin/tiktok/pixel/character",
    component: () => import("./components/TiktokPixelCharacter.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokPixelCharacter",
  },
  {
    path: "/admin/tiktok/character/random",
    component: () => import("./components/TiktokRandomCharacter.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokRandomCharacter",
  },
  {
    path: "/admin/tiktok/screenshot",
    component: () => import("./components/TiktokScreenshot.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokScreenshot",
  },
  {
    path: "/admin/tiktok/pixel/video",
    component: () => import("./components/TiktokPixelVideo.vue"),
    beforeEnter: checkAdminRights,
    name: "TiktokPixelVideo",
  },
  {
    path: "/admin/tiktok/menu",
    component: () => import("./components/TikTokMenu.vue"),
    beforeEnter: checkAdminRights,
    name: "tiktokmenu",
  },
  {
    path: "/admin/song/add",
    component: () => import("./components/AdminAddSongs.vue"),
    beforeEnter: checkAdminRights,
    name: "adminadd",
  },
  {
    path: "/admin/anime/screenshots/add",
    component: () => import("./components/AdminAddScreenshots.vue"),
    beforeEnter: checkAdminRights,
    name: "screenshotsadd",
  },
  {
    path: "/admin/tiktok/thumbnail",
    component: () => import("./components/TiktokThumbnail.vue"),
    beforeEnter: checkAdminRights,
    name: "tiktokthumbnail",
  },
  {
    path: "/admin/tiktok",
    component: () => import("./components/TikTok.vue"),
    beforeEnter: checkAdminRights,
    name: "tiktok",
  },
  {
    path: "/admin/tiktok/v2",
    component: () => import("./components/TikTokV2.vue"),
    beforeEnter: checkAdminRights,
    name: "tiktokv2",
  },
  {
    path: "/admin/tiktok/character",
    component: () => import("./components/TikTokCharacter.vue"),
    beforeEnter: checkAdminRights,
    name: "tiktokCharacter",
  },
  {
    path: "/admin/tiktok/easy",
    component: () => import("./components/TikTokEasy.vue"),
    beforeEnter: checkAdminRights,
    name: "tiktokEasy",
  },
  {
    path: "/modo/song/manage",
    component: () => import("./components/ModoSongManage.vue"),
    beforeEnter: checkAdminRights,
    name: "songmanage",
  },
  {
    path: "/admin",
    component: () => import("./components/AdminMenu.vue"),
    beforeEnter: checkAdminRights,
    name: "admin",
  },
  {
    path: "/admin/anime/manage",
    component: () => import("./components/AdminAnimeManage.vue"),
    beforeEnter: checkAdminRights,
    name: "adminanime",
  },
  { path: "/reset-password", component: () => import("./components/ResetPassword.vue"), name: "reset" },
  { path: "/privacy", component: () => import("./components/PrivacyPolicy.vue"), name: "privacy" },
  {
    path: "/anime/:title",
    name: "infos",
    component: () => import("./components/InfoAnime.vue"),
  },
  {
    path: "/testanime/:title",
    name: "testanime",
    component: () => import("./components/TestInfoAnime.vue"),
  },
  {
    path: "/maintenance",
    component: () => import("./components/MaintenanceView.vue"),
    name: "maintenance",
  },
  { path: "/404", component: () => import("./components/PageNotFound.vue"), name: "404" },
  { path: "/:pathMatch(.*)", component: () => import("./components/PageNotFound.vue"), name: "PageNotFound" },
];
const path = window.location.pathname;
const match = path.match(/^\/(en|fr)\//);
let getLocaleFromURL;
if (match) {
  getLocaleFromURL = match[0] == "/fr/" ? "fr" : match[0] == "/en/" ? "en" : null;
}
const userLang = navigator.language || navigator.userLanguage;
const defaultLang = getLocaleFromURL
  ? getLocaleFromURL
  : localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : userLang.startsWith("fr")
  ? "fr"
  : "en";
const router = createRouter({
  history: createWebHistory(defaultLang),
  routes,
});
function checkConnected(to, from, next) {
  const auth = useAuthStore();
  if (auth.user.role != "guest") {
    next();
  } else {
    next({ path: "/404" });
  }
}
function checkAdminRights(to, from, next) {
  const auth = useAuthStore();
  if (auth.user.role == "admin") {
    next();
  } else {
    next({ path: "/404" });
  }
}
router.beforeEach(async (to, from) => {
  const auth = useAuthStore();
  const joinRoom = useJoinRoomStore();
  window.scrollTo(0, 0);
  document.getElementById("color").style.visibility = "visible";
  document.getElementById("color").style.setProperty("width", "80%");
  if (!auth.user.idUser) {
    const { data: response } = await useFetch("/api/auth/reconnect", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authToken: useCookies().get("authToken"),
        guest: useCookies().get("guest"),
      }),
    });
    const userInfos = JSON.parse(response.value);
    if (userInfos?.[0]?.id) {
      auth.user.role = userInfos[0].type;
      auth.user.avatar = userInfos[0].avatar;
      if (!auth.user.avatar) {
        auth.user.avatar = "avatar.png";
      }
      auth.user.idUser = userInfos[0].id;
      auth.user.lvl = userInfos[0].lvl;
      auth.user.since = userInfos[0].since;
      auth.user.username = userInfos[0].username;
    } else if (useCookies().get("guest")) {
      auth.user.role = "guest";
      auth.user.idUser = useCookies().get("guest");
      auth.user.lvl = 0;
    } else {
      auth.user.role = "guest";
      auth.user.idUser = userInfos?.[0];
      auth.user.lvl = 0;
    }
  }

  if (
    (to.path.includes("/room") && to.query.id) ||
    (to.path.includes("/testroom") && to.query.id) ||
    (to.path.includes("/admin/tiktok") && to.query.id) ||
    (to.path.includes("/admin/tiktok/character") && to.query.id)
  ) {
    joinRoom.joinRoom(to.query.id);
  } else if (
    from.path.includes("/room") ||
    from.path.includes("/testroom") ||
    from.path.includes("/admin/tiktok") ||
    from.path.includes("/admin/tiktok/character")
  ) {
    joinRoom.leaveRoom();
  }
  // if (
  //   !localStorage.getItem("test") &&
  //   !to.path.includes("/maintenance")
  // ) {
  //   next("/maintenance");
  // } else {
  //   next();
  // }
});
export default router;
