<template>
  <ModalReuse2 :modal-active="modalActive" @close-modal="toggleModal"
    ><div class="w-full h-full flex items-center justify-center">
      <div
        @click.stop
        class="relative col-span-full overflow-hidden md:col-span-4 rounded-3xl w-full mx-4 sm:mx-14 lg:pb-[575px] max-w-[1023px] cursor-auto pb-[56.1798%]"
        style="box-shadow: 0px 15px 20px rgb(0 140 168 / 40%)"
      >
        <iframe
          class="absolute w-full h-full"
          :src="'https://www.youtube.com/embed/' + videoEmbed + '?start=' + videoStart"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </ModalReuse2>
  <ModalReuse2 :modal-active="screenshotsToggle" @close-modal="screenshotsToggleModal"
    ><div class="w-full h-full flex items-center justify-center">
      <div
        @click.stop
        class="relative col-span-full overflow-hidden md:col-span-4 rounded-3xl w-full mx-4 sm:mx-14 lg:pb-[575px] max-w-[1023px] cursor-auto pb-[56.1798%]"
        style="box-shadow: 0px 15px 20px rgb(0 140 168 / 40%)"
      >
        <v-img
          class="absolute w-full h-full"
          :src="'/images/screenshots/' + screenshots"
          :class="{ 'blur-xl': animeInfos?.nsfw == 18 }"
        >
        </v-img>
      </div>
    </div>
  </ModalReuse2>
  <ModalMenuAnime :modal-active="menuAnimeModalActive" @close-modal="menuAnimeToggleModal"
    ><div class="w-full h-full flex items-center justify-center">
      <div
        @click.stop
        class="rounded-2xl mx-4 sm:mx-14 cursor-auto overflow-hidden modal-menu-anime"
        style="box-shadow: 0px 15px 20px rgb(0 140 168 / 40%)"
      >
        <h3 class="bg-linear text-lg line-clamp-1 font-medium max-w-[402px] text-center p-1">
          {{ animeInfos?.title }}
        </h3>
        <div class="flex justify-center flex-wrap p-4 pt-5">
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#2ecc71] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 1 }"
              @click="menuAnime(1)"
            >
              <svg
                class="hover:text-[#2ecc71] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475q0 .25-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712"
                />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.watching") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#5d9cec] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 2 }"
              @click="menuAnime(2)"
            >
              <svg
                class="hover:text-[#5d9cec] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M13 11.6V8q0-.425-.288-.712T12 7q-.425 0-.712.288T11 8v3.975q0 .2.075.388t.225.337l3.3 3.3q.275.275.7.275T16 16q.275-.275.275-.7T16 14.6zM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.325 0 5.663-2.337T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 3.325 2.338 5.663T12 20"
                />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.to_watch") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#ac92ec] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 3 }"
              @click="menuAnime(3)"
            >
              <svg
                class="hover:text-[#ac92ec] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M7 14v6q0 .425-.288.713T6 21q-.425 0-.712-.288T5 20V5q0-.425.288-.712T6 4h7.175q.35 0 .625.225t.35.575L14.4 6H19q.425 0 .713.288T20 7v8q0 .425-.288.713T19 16h-5.175q-.35 0-.625-.225t-.35-.575L12.6 14z"
                />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.completed") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#fcc200] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 4 }"
              @click="menuAnime(4)"
            >
              <svg
                class="hover:text-[#fcc200] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16 19q-.825 0-1.412-.587T14 17V7q0-.825.588-1.412T16 5q.825 0 1.413.588T18 7v10q0 .825-.587 1.413T16 19m-8 0q-.825 0-1.412-.587T6 17V7q0-.825.588-1.412T8 5q.825 0 1.413.588T10 7v10q0 .825-.587 1.413T8 19"
                />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.on_hold") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#d8334a] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 5 }"
              @click="menuAnime(5)"
            >
              <svg
                class="hover:text-[#d8334a] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M7 15.385v-6.77q0-.666.475-1.14Q7.949 7 8.615 7h6.77q.666 0 1.14.475q.475.474.475 1.14v6.77q0 .666-.475 1.14q-.474.475-1.14.475h-6.77q-.666 0-1.14-.475Q7 16.051 7 15.385"
                />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.dropped") }}</span>
          </div>
        </div>
        <div class="flex justify-evenly flex-wrap p-4 pt-0">
          <div class="flex flex-col items-center mb-4">
            <div class="flex">
              <svg
                class="cursor-pointer mt-[6px]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                @click="rating = 0"
              >
                <path
                  fill="currentColor"
                  d="m12 13.4l2.9 2.9q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7L13.4 12l2.9-2.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L12 10.6L9.1 7.7q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l2.9 2.9l-2.9 2.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275zm0 8.6q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"
                />
              </svg>
              <v-rating
                hover
                half-increments
                v-model="rating"
                :length="5"
                :size="24"
                :model-value="animeUser?.score / 2"
                color="#ffffff"
                active-color="#FFB600"
                class="m-0 h-[26px]"
              />
            </div>
            <span class="font-medium text-sm mt-1">{{ $t("my_list.rating") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px]">
            <button
              class="rounded-full bg-[#ff7d7d] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.favorite === 1 }"
              @click="menuFavAnime"
            >
              <svg
                class="hover:text-[#ff7d7d] duration-300 w-full h-full p-2 mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 20.325q-.35 0-.712-.125t-.638-.4l-1.725-1.575q-2.65-2.425-4.788-4.812T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.325 0 2.5.562t2 1.538q.825-.975 2-1.537t2.5-.563q2.35 0 3.925 1.575T22 8.15q0 2.875-2.125 5.275T15.05 18.25l-1.7 1.55q-.275.275-.637.4t-.713.125"
                />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.favorite") }}</span>
          </div>
        </div>
      </div>
    </div>
  </ModalMenuAnime>
  <div class="max-w-[1200px] mx-auto mb-24 md:mb-0 md:px-8 w-screen justify-self-center px-4 absolute md:static">
    <div
      class="max-h-[200px] md:max-h-[400px] overflow-hidden bg-first"
      style="
        position: absolute;
        left: -2vw;
        top: 0px;
        width: 104%;
        filter: brightness(0.7);
        border-bottom-left-radius: 35%;
        border-bottom-right-radius: 35%;
      "
    >
      <v-skeleton-loader
        v-if="!isLoading"
        type="image"
        class="h-[200px] md:h-[400px]"
        color="#00000000"
      ></v-skeleton-loader>
      <v-parallax class="blur-lg" @load="isLoading = true" :src="'/images/original/' + animeInfos?.poster"></v-parallax>
    </div>
    <div class="relative bottom-[20px] hidden md:block">
      <div style="height: 340px; display: flex">
        <div
          class="anime-title fs-min titre-anime lg:pr-[25px] lg:pl-[40%] px-[40px]"
          style="
            line-height: 1;
            height: 100%;
            margin: 0;
            max-width: 1200px;
            color: #eee;
            -webkit-line-clamp: 5;
            text-overflow: ellipsis;
            overflow: visible;
            -webkit-box-orient: vertical;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <h1 class="other-name font-medium" style="font-size: 40px; margin-left: -2px">
            {{ animeInfos?.title }} {{ auth.user.role == "admin" ? "(" + animeInfos?.id + ")" : "" }}
          </h1>
          <br />
          <h2>{{ animeInfos?.others }}</h2>
        </div>
      </div>
    </div>
    <div>
      <div class="flex md:flex-row flex-col">
        <div class="max-w-[270px] md:max-w-[340px] w-4/5 self-center md:self-start">
          <div class="mb-4 flex justify-center items-center imgPoster relative">
            <div class="absolute left-1 top-1 z-10 lg:mt-[-160px] mt-[30px] flex">
              <div class="flex flex-col items-center" v-if="animeUser?.status === 1">
                <div class="rounded-[1.25rem] bg-[#2ecc71] w-[30px] h-[30px] flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475q0 .25-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col items-center" v-if="animeUser?.status === 2">
                <div class="rounded-[1.25rem] bg-[#5d9cec] w-[30px] h-[30px] flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M13 11.6V8q0-.425-.288-.712T12 7q-.425 0-.712.288T11 8v3.975q0 .2.075.388t.225.337l3.3 3.3q.275.275.7.275T16 16q.275-.275.275-.7T16 14.6zM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.325 0 5.663-2.337T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 3.325 2.338 5.663T12 20"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col items-center" v-if="animeUser?.status === 3">
                <div class="rounded-[1.25rem] bg-[#ac92ec] w-[30px] h-[30px] flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M7 14v6q0 .425-.288.713T6 21q-.425 0-.712-.288T5 20V5q0-.425.288-.712T6 4h7.175q.35 0 .625.225t.35.575L14.4 6H19q.425 0 .713.288T20 7v8q0 .425-.288.713T19 16h-5.175q-.35 0-.625-.225t-.35-.575L12.6 14z"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col items-center" v-if="animeUser?.status === 4">
                <div class="rounded-[1.25rem] bg-[#fcc200] w-[30px] h-[30px] flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M16 19q-.825 0-1.412-.587T14 17V7q0-.825.588-1.412T16 5q.825 0 1.413.588T18 7v10q0 .825-.587 1.413T16 19m-8 0q-.825 0-1.412-.587T6 17V7q0-.825.588-1.412T8 5q.825 0 1.413.588T10 7v10q0 .825-.587 1.413T8 19"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col items-center" v-if="animeUser?.status === 5">
                <div class="rounded-[1.25rem] bg-[#d8334a] w-[30px] h-[30px] flex justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M7 15.385v-6.77q0-.666.475-1.14Q7.949 7 8.615 7h6.77q.666 0 1.14.475q.475.474.475 1.14v6.77q0 .666-.475 1.14q-.474.475-1.14.475h-6.77q-.666 0-1.14-.475Q7 16.051 7 15.385"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex flex-col items-center ml-1" v-if="animeUser?.favorite === 1">
                <div class="rounded-[1.25rem] bg-[#ff7d7d] w-[30px] h-[30px] flex justify-center items-center">
                  <svg class="mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 20.325q-.35 0-.712-.125t-.638-.4l-1.725-1.575q-2.65-2.425-4.788-4.812T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.325 0 2.5.562t2 1.538q.825-.975 2-1.537t2.5-.563q2.35 0 3.925 1.575T22 8.15q0 2.875-2.125 5.275T15.05 18.25l-1.7 1.55q-.275.275-.637.4t-.713.125"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <v-img
              :alt="animeInfos?.title"
              :src="'/images/original/' + animeInfos?.poster"
              :class="{ 'blur-md': animeInfos?.nsfw == 18 }"
              loading="lazy"
              class="mt-[30px] md:mt-0 lg:mt-[-160px] rounded-[2rem]"
            >
              <template v-slot:placeholder>
                <v-sheet class="h-full">
                  <v-skeleton-loader
                    type="image"
                    class="rounded-[2rem] overflow-hidden"
                    color="#00000000"
                  ></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
            <v-tooltip text="Trailer" location="top" v-if="animeInfos?.trailer">
              <template v-slot:activator="{ props }">
                <svg
                  v-bind="props"
                  @click="
                    videoEmbed = animeInfos?.trailer;
                    toggleModal(1);
                  "
                  class="mt-[40px] lg:mt-[-160px] absolute cursor-pointer outline-none lax-w-[60px] w-[10vw]"
                  fill="#ffffff"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="60"
                  height="60"
                  viewBox="0 0 48 48"
                >
                  <g>
                    <circle cx="25" cy="25" r="20" fill="#0f112b80"></circle>
                    <path
                      d="M24,47C11.31738,47,1,36.68262,1,24S11.31738,1,24,1s23,10.31738,23,23S36.68262,47,24,47z M24,5 C13.52344,5,5,13.52344,5,24s8.52344,19,19,19s19-8.52344,19-19S34.47656,5,24,5z"
                    ></path>
                    <path
                      d="M19,33c-0.16211,0-0.32422-0.03906-0.47168-0.11816C18.20312,32.70801,18,32.36914,18,32V16 c0-0.36914,0.20312-0.70801,0.52832-0.88184c0.32422-0.17383,0.71973-0.15332,1.02637,0.0498l12,8 C31.83301,23.35352,32,23.66602,32,24s-0.16699,0.64648-0.44531,0.83203l-12,8C19.3877,32.94336,19.19434,33,19,33z"
                    ></path>
                  </g>
                </svg>
              </template>
            </v-tooltip>
          </div>
          <div class="hidden md:flex flex-wrap justify-between flex-col lg:flex-row items-center lg:items-start">
            <div>
              <span class="font-medium">{{ $t("infos.episode") }} </span>
              <span v-if="animeInfos?.type == 'TV' || animeInfos?.type == 'ona'">{{ animeInfos?.nbEp }}</span>
              <span v-else>0</span>
            </div>
            <div>
              <span class="font-medium">{{ $t("infos.ova") }} </span>
              <span v-if="animeInfos?.type == 'oav'">{{ animeInfos?.nbEp }}</span>
              <span v-else>0</span>
            </div>
            <div>
              <span class="font-medium">{{ $t("infos.movie") }} </span>
              <span v-if="animeInfos?.type == 'film'">{{ animeInfos?.nbEp }}</span>
              <span v-else>0</span>
            </div>
          </div>
        </div>
        <div class="w-full md:ml-8">
          <div class="flex">
            <div class="w-full">
              <div class="md:hidden text-center mb-5 mt-1">
                <h1 class="font-medium text-2xl mb-2" style="line-height: 1.1">{{ animeInfos?.title }}</h1>
                <h2 class="font-medium line-clamp-3 leading-5">{{ animeInfos?.others }}</h2>
              </div>
              <div class="md:hidden flex justify-between flex-row mb-4">
                <div>
                  <span class="font-medium">{{ $t("infos.episode") }} </span>
                  <span v-if="animeInfos?.type == 'TV' || animeInfos?.type == 'ona'">{{ animeInfos?.nbEp }}</span>
                  <span v-else>0</span>
                </div>
                <div>
                  <span class="font-medium">{{ $t("infos.ova") }} </span>
                  <span v-if="animeInfos?.type == 'oav'">{{ animeInfos?.nbEp }}</span>
                  <span v-else>0</span>
                </div>
                <div>
                  <span class="font-medium">{{ $t("infos.movie") }} </span>
                  <span v-if="animeInfos?.type == 'film'">{{ animeInfos?.nbEp }}</span>
                  <span v-else>0</span>
                </div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M3 22v-1.5h2.5v-.75H4v-1.5h1.5v-.75H3V16h3q.425 0 .713.288T7 17v1q0 .425-.288.713T6 19q.425 0 .713.288T7 20v1q0 .425-.288.713T6 22H3Zm0-7v-2.75q0-.425.288-.712T4 11.25h1.5v-.75H3V9h3q.425 0 .713.288T7 10v1.75q0 .425-.288.713T6 12.75H4.5v.75H7V15H3Zm1.5-7V3.5H3V2h3v6H4.5ZM9 19v-2h12v2H9Zm0-6v-2h12v2H9Zm0-6V5h12v2H9Z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.score") }}</span>
                </div>
                <div v-if="animeInfos?.nbRating == 0 || animeInfos?.nbRating == 1" class="self-center">
                  {{ animeInfos?.avgRating || "-" }}/10⭐({{ animeInfos?.nbRating }} {{ $t("infos.vote") }})
                </div>
                <div v-else class="self-center">
                  {{ animeInfos?.avgRating }}/10⭐({{ animeInfos?.nbRating }} {{ $t("infos.votes") }})
                </div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M10 13.5h3.48v-1H10v1Zm0-3h6.962v-1H10v1Zm0-3h6.962v-1H10v1ZM8.115 17q-.69 0-1.152-.462q-.463-.463-.463-1.153V4.615q0-.69.463-1.152Q7.425 3 8.115 3h10.77q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.153q-.463.462-1.153.462H8.115Zm-3 3q-.69 0-1.152-.462q-.463-.463-.463-1.153V6.615h1v11.77q0 .23.192.423q.193.192.423.192h11.77v1H5.115Z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.source") }}</span>
                </div>
                <div class="self-center" v-if="selectedLanguage == 'en' && animeInfos?.origin == 'Autre'">
                  {{ $t("infos.other") }}
                </div>
                <div class="self-center" v-else-if="selectedLanguage == 'en' && animeInfos?.origin == 'Inconnu'">
                  {{ $t("infos.unknown") }}
                </div>
                <div class="self-center" v-else-if="selectedLanguage == 'en' && animeInfos?.origin == 'Jeu video'">
                  {{ $t("infos.video_game") }}
                </div>
                <div class="self-center" v-else-if="selectedLanguage == 'en' && animeInfos?.origin == 'Livre'">
                  {{ $t("infos.book") }}
                </div>
                <div
                  class="self-center"
                  v-else-if="selectedLanguage == 'en' && animeInfos?.origin == 'Oeuvre original'"
                >
                  {{ $t("infos.original_anime") }}
                </div>
                <div class="self-center" v-else-if="selectedLanguage == 'en' && animeInfos?.origin == 'Roman'">
                  {{ $t("infos.novel") }}
                </div>
                <div class="self-center" v-else>{{ animeInfos?.origin }}</div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m12.7 14.05l5.65-5.65l-1.4-1.45l-4.25 4.25l-2.15-2.1l-1.4 1.4l3.55 3.55ZM8 18q-.825 0-1.412-.587T6 16V4q0-.825.588-1.412T8 2h12q.825 0 1.413.588T22 4v12q0 .825-.587 1.413T20 18H8Zm-4 4q-.825 0-1.412-.587T2 20V6h2v14h14v2H4Z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.themes") }}</span>
                </div>
                <div class="self-center max-h-[46px] line-clamp-2" v-if="selectedLanguage == 'fr'">
                  {{ animeInfos?.tagFr || $t("infos.unknown") }}
                </div>
                <div class="self-center max-h-[46px] line-clamp-2" v-else>
                  {{ animeInfos?.tag || $t("infos.unknown") }}
                </div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 16q1.875 0 3.188-1.312T16.5 11.5q0-1.875-1.312-3.187T12 7q-1.875 0-3.187 1.313T7.5 11.5q0 1.875 1.313 3.188T12 16Zm0-1.8q-1.125 0-1.912-.788T9.3 11.5q0-1.125.788-1.912T12 8.8q1.125 0 1.913.788T14.7 11.5q0 1.125-.787 1.913T12 14.2Zm0 4.8q-3.65 0-6.65-2.037T1 11.5q1.35-3.425 4.35-5.462T12 4q3.65 0 6.65 2.038T23 11.5q-1.35 3.425-4.35 5.463T12 19Z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.rating") }}</span>
                </div>
                <div v-if="animeInfos?.nsfw == 1" class="self-center">{{ $t("infos.kid") }}</div>
                <div v-else-if="animeInfos?.nsfw == 0" class="self-center">{{ $t("infos.all_audiences") }}</div>
                <div v-else class="self-center">+{{ animeInfos?.nsfw }}</div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17 9V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-2l5 4V5l-5 4z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.studio") }}</span>
                </div>
                <div class="self-center">{{ animeInfos?.studio || $t("infos.unknown") }}</div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5Z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.duration") }}</span>
                </div>
                <div class="self-center">{{ animeInfos?.avgTime || $t("infos.unknown") }}</div>
              </div>
              <div class="flex mb-3">
                <div class="w-[180px] min-w-[180px] md:w-[200px] md:min-w-[200px] flex">
                  <svg
                    class="mr-4 self-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M8 14q-.425 0-.712-.288T7 13q0-.425.288-.712T8 12q.425 0 .713.288T9 13q0 .425-.288.713T8 14Zm4 0q-.425 0-.712-.288T11 13q0-.425.288-.712T12 12q.425 0 .713.288T13 13q0 .425-.288.713T12 14Zm4 0q-.425 0-.712-.288T15 13q0-.425.288-.712T16 12q.425 0 .713.288T17 13q0 .425-.288.713T16 14ZM5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V3q0-.425.288-.712T7 2q.425 0 .713.288T8 3v1h8V3q0-.425.288-.712T17 2q.425 0 .713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22H5Zm0-2h14V10H5v10Z"
                    />
                  </svg>
                  <span class="self-center font-medium">{{ $t("infos.aired") }}</span>
                </div>
                <div v-if="animeInfos?.fin" class="self-center">
                  {{ $t("infos.from") }} {{ formaterDate(animeInfos?.debut) }} {{ $t("infos.to") }}
                  {{ formaterDate(animeInfos?.fin) }}
                </div>
                <div v-else class="self-center">{{ formaterDate(animeInfos?.debut) }}</div>
              </div>
            </div>
            <div class="actionBtn">
              <button
                class="lg:ml-4 md:mr-6 md: hover:scale-110 bg-linear menu-anime md:static md:h-[46px] md:w-[46px] h-[48px] w-[48px] z-10"
                @click="menuAnimeToggleModal(1)"
              >
                <span class="line1"></span>
                <span class="line2"></span>
              </button>
            </div>
          </div>
          <div class="anime-genres flex flex-wrap md:mt-2">
            <div
              v-if="animeInfos?.genre.indexOf('action') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #f6bb42"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19 16V6h3v10zM12 4H7S2 4 2 8v6c0 1.77 1 2.76 2.07 3.31A3.996 3.996 0 0 1 8 14h3v2H8a2 2 0 0 0-2 2a2 2 0 0 0 2 2h5c4 0 4-4 4-4V6s-1-2-5-2"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.action")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('aventure') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #8cc152"
            >
              <v-icon class="text-white">mdi-compass</v-icon>

              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.adventure")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('comedie') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #e8ce4d"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M7 22q-2.5 0-4.25-1.75T1 16V9h12v7q0 2.5-1.75 4.25T7 22m-2-7.5q.425 0 .713-.288T6 13.5q0-.425-.288-.712T5 12.5q-.425 0-.712.288T4 13.5q0 .425.288.713T5 14.5m2 3.4q.975 0 1.738-.513T9.5 16h-5q0 .875.763 1.388T7 17.9m2-3.4q.425 0 .713-.287T10 13.5q0-.425-.288-.712T9 12.5q-.425 0-.712.288T8 13.5q0 .425.288.713T9 14.5m8 .5q-.65 0-1.287-.137T14.5 14.45V7.5H11V2h12v7q0 2.5-1.75 4.25T17 15m-2-7.5q.425 0 .713-.288T16 6.5q0-.425-.288-.712T15 5.5q-.425 0-.712.288T14 6.5q0 .425.288.713T15 7.5m-.5 3.4h5q0-.875-.763-1.388T17 9q-.85 0-1.675.45T14.5 10.9M19 7.5q.425 0 .713-.288T20 6.5q0-.425-.288-.712T19 5.5q-.425 0-.712.288T18 6.5q0 .425.288.713T19 7.5"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.comedy")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('drame') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #3c3b3d"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12 2c5.523 0 10 4.477 10 10a9.959 9.959 0 0 1-1.065 4.496a1.975 1.975 0 0 0-.398-.775l-.123-.135L19 14.172l-1.414 1.414l-.117.127a2 2 0 0 0 1.679 3.282A9.974 9.974 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2m0 13c-1.38 0-2.63.56-3.534 1.463l-.166.174l.945.86C10.035 17.182 10.982 17 12 17c.905 0 1.754.144 2.486.396l.269.1l.945-.86A4.987 4.987 0 0 0 12 15m-3.5-5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m7 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.drama") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('ecchi') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #ec87c0"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                <path
                  fill="currentColor"
                  d="M226 88.08c-.4-1-.82-2-1.25-3a87.93 87.93 0 0 0-30.17-37H216a8 8 0 0 0 0-16H112a88.12 88.12 0 0 0-87.72 81A32 32 0 0 0 0 144a8 8 0 0 0 16 0a16 16 0 0 1 8.57-14.16A87.69 87.69 0 0 0 46 178.22l12.56 35.16A16 16 0 0 0 73.64 224h12.72a16 16 0 0 0 15.07-10.62l1.92-5.38h57.3l1.92 5.38A16 16 0 0 0 177.64 224h12.72a16 16 0 0 0 15.07-10.62L221.64 168H224a24 24 0 0 0 24-24v-32a24 24 0 0 0-22-23.92M152 72h-40a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16m28 56a12 12 0 1 1 12-12a12 12 0 0 1-12 12"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.ecchi") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('fantastique') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #2aba66"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M14.5 12a2.5 2.5 0 0 1-5 0a2.5 2.5 0 0 1 5 0m7.5 0c0 5.52-4.48 10-10 10S2 17.52 2 12S6.48 2 12 2s10 4.48 10 10m-2 0h-4c0-2.21-1.79-4-4-4s-4 1.79-4 4H4c0 4.41 3.59 8 8 8s8-3.59 8-8"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.fantastic")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('historique') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #aa8e69"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M6.5 20c1.7 0 4.15.65 5.5 1.5c1.35-.85 3.8-1.5 5.5-1.5c1.65 0 3.35.3 4.75 1.05c.1.05.15.05.25.05c.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1c-1.11-.35-2.33-.5-3.5-.5c-1.95 0-4.05.4-5.5 1.5c-1.45-1.1-3.55-1.5-5.5-1.5c-1.17 0-2.39.15-3.5.5c-.75.25-1.4.55-2 1v14.6c0 .25.25.5.5.5c.1 0 .15 0 .25-.05C3.15 20.3 4.85 20 6.5 20m5.5-.5V8c1.35-.85 3.8-1.5 5.5-1.5c1.2 0 2.4.15 3.5.5v11.5c-1.1-.35-2.3-.5-3.5-.5c-1.7 0-4.15.65-5.5 1.5"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.historical")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('horreur') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #434a54"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M6.5 16.5h-2q-.46 0-.73-.27q-.27-.27-.27-.73V7H3q-.213 0-.356-.144q-.144-.144-.144-.357t.144-.356Q2.787 6 3 6h3.5V3.5h-1q-.213 0-.356-.144Q5 3.212 5 3t.144-.356Q5.288 2.5 5.5 2.5h3q.213 0 .356.144Q9 2.788 9 3t-.144.356Q8.713 3.5 8.5 3.5h-1V6H11q.213 0 .356.144q.144.144.144.357t-.144.356Q11.213 7 11 7h-.5v8.5q0 .46-.27.73q-.27.27-.73.27h-2V21q0 .213-.144.356q-.144.144-.357.144t-.356-.144Q6.5 21.213 6.5 21zm-2-1h5v-2.308H7.25q-.177 0-.31-.132q-.132-.133-.132-.31t.132-.31q.133-.132.31-.132H9.5v-2.116H7.25q-.177 0-.31-.132q-.132-.133-.132-.31t.132-.31q.133-.132.31-.132H9.5V7h-5zm10 6q-.46 0-.73-.27q-.27-.27-.27-.73v-7q0-.717.308-1.186q.307-.47.582-.781q.256-.293.433-.515q.177-.222.177-.518v-1h-.5q-.213 0-.356-.144Q14 9.212 14 9t.144-.356q.144-.143.356-.143h5q.213 0 .356.144Q20 8.788 20 9t-.144.356q-.143.143-.356.143H19v1q0 .296.183.527q.182.23.444.523q.275.312.574.777q.299.465.299 1.166V20.5q0 .46-.27.73q-.27.27-.73.27zm0-7.192h5V13.5q0-.49-.225-.823q-.225-.333-.538-.677q-.262-.28-.5-.626Q18 11.03 18 10.5v-1h-2v1q0 .518-.238.863q-.237.345-.493.631q-.313.344-.541.68q-.228.336-.228.826zm0 6.192h5v-2.308h-5z"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.horror")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('isekai') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #baa286"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12q0-.175-.012-.363t-.013-.312q-.125.725-.675 1.2T18 13h-2q-.825 0-1.412-.587T14 11v-1h-4V8q0-.825.588-1.412T12 6h1q0-.575.313-1.012t.762-.713q-.5-.125-1.012-.2T12 4Q8.65 4 6.325 6.325T4 12h5q1.65 0 2.825 1.175T13 16v1h-3v2.75q.5.125.988.188T12 20"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.isekai")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('josei') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #48cfad"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.josei") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('magique') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #e9573f"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 14 14">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="m12.35 3.824l.84-2.48a.4.4 0 0 0-.51-.51l-2.48.84a.41.41 0 0 1-.37-.05L7.73.054a.4.4 0 0 0-.6.33v2.62a.43.43 0 0 1-.17.33l-2.14 1.51a.4.4 0 0 0 .11.71l2.5.78a.38.38 0 0 1 .26.26l.78 2.5a.4.4 0 0 0 .71.11l1.46-2.1a.43.43 0 0 1 .33-.17h2.62a.4.4 0 0 0 .33-.64l-1.57-2.1a.41.41 0 0 1 0-.37M.293 12.293l5.241-5.241l1.096.342l.33 1.06l-5.253 5.253a1 1 0 0 1-1.414-1.414"
                  clip-rule="evenodd"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.magic") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('mecha') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #aab2bd"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M18.41 4L16 6.41v.18L18.41 9H22v2h-4.41L16 9.41V12h-1a2 2 0 0 1-2-2V7.5H9.86c-.09.37-.24.72-.44 1.05L15.18 19H20a2 2 0 0 1 2 2v1H2v-1a2 2 0 0 1 2-2h6.61l-4.69-8.5a4.006 4.006 0 0 1-3.81-3A4 4 0 0 1 5 2.63c2.13-.55 4.31.73 4.86 2.87H13V3a2 2 0 0 1 2-2h1v2.59L17.59 2H22v2zM6 4.5a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.mecha") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('musique') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #a0cecb"
            >
              <v-icon class="text-white">mdi-music-note</v-icon>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.music") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('mystere') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #8067b7"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M11.75 19h-.25q-3.55 0-6.025-2.475T3 10.5q0-3.55 2.475-6.025T11.5 2q1.775 0 3.313.662t2.7 1.825q1.162 1.163 1.824 2.7T20 10.5q0 3.35-1.888 6.225t-4.762 4.5q-.25.125-.5.138t-.45-.113q-.2-.125-.35-.325t-.175-.475zm-.275-3.025q.425 0 .725-.3t.3-.725q0-.425-.3-.725t-.725-.3q-.425 0-.725.3t-.3.725q0 .425.3.725t.725.3M9.3 8.375q.275.125.55.013t.45-.363q.225-.3.525-.463T11.5 7.4q.6 0 .975.337t.375.863q0 .325-.187.65t-.663.8q-.625.55-.925 1.038t-.3.987q0 .3.213.513t.512.212q.3 0 .5-.225t.3-.525q.125-.425.45-.775t.6-.625q.525-.525.788-1.05t.262-1.05q0-1.15-.788-1.85T11.5 6q-.8 0-1.475.388t-1.1 1.062q-.15.275-.038.538t.413.387"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.mystery")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('psychologique') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #6a50a7"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M21.33 12.91c.09 1.55-.62 3.04-1.89 3.95l.77 1.49c.23.45.26.98.06 1.45c-.19.47-.58.84-1.06 1l-.79.25a1.687 1.687 0 0 1-1.86-.55L14.44 18c-.89-.15-1.73-.53-2.44-1.1c-.5.15-1 .23-1.5.23c-.88 0-1.76-.27-2.5-.79c-.53.16-1.07.23-1.62.22c-.79.01-1.57-.15-2.3-.45a4.105 4.105 0 0 1-2.43-3.61c-.08-.72.04-1.45.35-2.11c-.29-.75-.32-1.57-.07-2.33C2.3 7.11 3 6.32 3.87 5.82c.58-1.69 2.21-2.82 4-2.7c1.6-1.5 4.05-1.66 5.83-.37c.42-.11.86-.17 1.3-.17c1.36-.03 2.65.57 3.5 1.64c2.04.53 3.5 2.35 3.58 4.47c.05 1.11-.25 2.2-.86 3.13c.07.36.11.72.11 1.09m-5-1.41c.57.07 1.02.5 1.02 1.07a1 1 0 0 1-1 1h-.63c-.32.9-.88 1.69-1.62 2.29c.25.09.51.14.77.21c5.13-.07 4.53-3.2 4.53-3.25a2.592 2.592 0 0 0-2.69-2.49a1 1 0 0 1-1-1a1 1 0 0 1 1-1c1.23.03 2.41.49 3.33 1.3c.05-.29.08-.59.08-.89c-.06-1.24-.62-2.32-2.87-2.53c-1.25-2.96-4.4-1.32-4.4-.4c-.03.23.21.72.25.75a1 1 0 0 1 1 1c0 .55-.45 1-1 1c-.53-.02-1.03-.22-1.43-.56c-.48.31-1.03.5-1.6.56c-.57.05-1.04-.35-1.07-.9a.968.968 0 0 1 .88-1.1c.16-.02.94-.14.94-.77c0-.66.25-1.29.68-1.79c-.92-.25-1.91.08-2.91 1.29C6.75 5 6 5.25 5.45 7.2C4.5 7.67 4 8 3.78 9c1.08-.22 2.19-.13 3.22.25c.5.19.78.75.59 1.29c-.19.52-.77.78-1.29.59c-.73-.32-1.55-.34-2.3-.06c-.32.27-.32.83-.32 1.27c0 .74.37 1.43 1 1.83c.53.27 1.12.41 1.71.4c-.15-.26-.28-.53-.39-.81a1.038 1.038 0 0 1 1.96-.68c.4 1.14 1.42 1.92 2.62 2.05c1.37-.07 2.59-.88 3.19-2.13c.23-1.38 1.34-1.5 2.56-1.5m2 7.47l-.62-1.3l-.71.16l1 1.25zm-4.65-8.61a1 1 0 0 0-.91-1.03c-.71-.04-1.4.2-1.93.67c-.57.58-.87 1.38-.84 2.19a1 1 0 0 0 1 1c.57 0 1-.45 1-1c0-.27.07-.54.23-.76c.12-.1.27-.15.43-.15c.55.03 1.02-.38 1.02-.92"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.psychological")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('romance') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #bf263c"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12 20.325q-.35 0-.712-.125t-.638-.4l-1.725-1.575q-2.65-2.425-4.788-4.812T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.325 0 2.5.562t2 1.538q.825-.975 2-1.537t2.5-.563q2.35 0 3.925 1.575T22 8.15q0 2.875-2.125 5.275T15.05 18.25l-1.7 1.55q-.275.275-.637.4t-.713.125"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.romance")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('sci_fi') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #3bafda"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M6.952 12.67q.63-1.42 1.447-2.705q.816-1.284 1.793-2.461L8.777 7.21q-.404-.081-.783.03q-.379.112-.67.404L4.537 10.43q-.298.298-.22.727q.078.429.482.59zm12.527-8.847q-2.304.048-4.345 1.025T11.467 7.45q-1.046 1.046-1.853 2.273q-.806 1.227-1.443 2.565q-.125.268-.125.548t.212.49l2.567 2.549q.212.212.482.221q.27.01.537-.115q1.339-.618 2.566-1.443q1.227-.826 2.273-1.873Q18.308 11.04 19.285 9t1.025-4.345q0-.162-.062-.304q-.061-.142-.192-.273q-.131-.13-.273-.192q-.143-.062-.304-.062m-5.485 6.315q-.44-.44-.44-1.066q0-.626.44-1.066q.44-.44 1.067-.44q.626 0 1.066.44q.44.44.44 1.066q0 .626-.44 1.066q-.44.44-1.066.44q-.626 0-1.067-.44m-2.55 7.043l.923 2.152q.162.404.59.484q.43.081.728-.217l2.784-2.785q.293-.292.404-.683q.112-.392.03-.795l-.288-1.397q-1.182.977-2.464 1.794q-1.282.816-2.707 1.447m-6.298-1.035q.587-.586 1.423-.58q.837.007 1.423.594q.587.586.587 1.423q0 .836-.587 1.423q-.834.834-1.962 1.05q-1.128.215-2.278.325q.11-1.17.337-2.288q.228-1.118 1.057-1.947"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.sci_fi")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('seinen') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #8e8271"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.seinen")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('shojo') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #d770ad"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.shojo") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('shonen') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #4a89dc"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.shonen")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('sport') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #7db1b1"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9.075 20.975q-1.175.125-2.837-.062T3.7 20.3q-.35-.8-.587-2.5T3 14.9zm2.375-.4l-8.05-8.05q.425-1.875 1.238-3.412t1.887-2.638Q7.6 5.4 9.138 4.613t3.312-1.188l8.1 8.1q-.4 1.85-1.175 3.4t-1.85 2.625q-1.125 1.1-2.687 1.888t-3.388 1.137M10.1 15.3l5.2-5.2q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275l-5.2 5.2q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275m10.85-6.15l-6.075-6.125q1.2-.15 2.95.05T20.3 3.7q.45 1 .625 2.588t.025 2.862"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.sport") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('surnaturel') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #ed5565"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M17.09 4.56c-.7-1.03-1.5-1.99-2.4-2.85c-.35-.34-.94-.02-.84.46c.19.94.39 2.18.39 3.29c0 2.06-1.35 3.73-3.41 3.73c-1.54 0-2.8-.93-3.35-2.26c-.1-.2-.14-.32-.2-.54c-.11-.42-.66-.55-.9-.18c-.18.27-.35.54-.51.83A13.772 13.772 0 0 0 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8c0-3.49-1.08-6.73-2.91-9.44M11.71 19c-1.78 0-3.22-1.4-3.22-3.14c0-1.62 1.05-2.76 2.81-3.12c1.47-.3 2.98-.93 4.03-1.92c.28-.26.74-.14.82.23c.23 1.02.35 2.08.35 3.15c.01 2.65-2.14 4.8-4.79 4.8"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.surnatural")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('thriller') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #5d9cec"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                <path
                  fill="currentColor"
                  d="M256 120a8 8 0 0 1-8 8H8a8 8 0 0 1 0-16h27.92l47.5-65.41a16 16 0 0 1 25.31-.72l12.85 14.9l.2.23a7.95 7.95 0 0 0 12.44 0l.2-.23l12.85-14.9a16 16 0 0 1 25.31.72l47.5 65.41H248a8 8 0 0 1 8 8m-76 24a36 36 0 0 0-35.77 32h-32.46a36 36 0 1 0-1.83 16h36.12A36 36 0 1 0 180 144"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.thriller")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('tranche_de_vie') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #2ecc71"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M5 21q-.425 0-.712-.288T4 20q0-.425.288-.712T5 19h14q.425 0 .713.288T20 20q0 .425-.288.713T19 21zm3-4q-1.65 0-2.825-1.175T4 13V5q0-.825.588-1.412T6 3h14q.825 0 1.413.588T22 5v3q0 .825-.587 1.413T20 10h-2v3q0 1.65-1.175 2.825T14 17zm10-9h2V5h-2z"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                $t("infos.type.slice_of_life")
              }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('yaoi') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #e0c341"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M20 5v4q0 .425-.288.713T19 10q-.425 0-.712-.288T18 9V7.425l-3.975 3.95q.475.7.725 1.488T15 14.5q0 2.3-1.6 3.9T9.5 20q-2.3 0-3.9-1.6T4 14.5q0-2.3 1.6-3.9T9.5 9q.825 0 1.625.237t1.475.738L16.575 6H15q-.425 0-.712-.288T14 5q0-.425.288-.712T15 4h4q.425 0 .713.288T20 5M9.5 11q-1.45 0-2.475 1.025T6 14.5q0 1.45 1.025 2.475T9.5 18q1.45 0 2.475-1.025T13 14.5q0-1.45-1.025-2.475T9.5 11"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.yaoi") }}</span>
            </div>

            <div
              v-if="animeInfos?.genre.indexOf('yuri') !== -1 && animeInfos?.genre"
              class="anime-genre"
              style="background-color: #fc6e51"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M11 19h-1q-.425 0-.712-.288T9 18q0-.425.288-.712T10 17h1v-2.1q-1.975-.35-3.238-1.888T6.5 9.45q0-2.275 1.613-3.862T12 4q2.275 0 3.888 1.588T17.5 9.45q0 2.025-1.263 3.563T13 14.9V17h1q.425 0 .713.288T15 18q0 .425-.288.713T14 19h-1v1q0 .425-.288.713T12 21q-.425 0-.712-.288T11 20zm1-6q1.45 0 2.475-1.025T15.5 9.5q0-1.45-1.025-2.475T12 6q-1.45 0-2.475 1.025T8.5 9.5q0 1.45 1.025 2.475T12 13"
                />
              </svg>
              <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{ $t("infos.type.yuri") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full mt-5" v-if="selectedLanguage == 'fr'">
        {{ animeInfos?.description || $t("infos.no_synopsis") }}
      </div>
      <div class="w-full mt-5" v-else>
        {{ animeInfos?.descriptionEn || $t("infos.no_synopsis") }}
      </div>
    </div>
    <div class="mt-9 mb-8">
      <div class="font-medium text-xl mb-4">{{ $t("infos.related_anime") }}</div>
      <swiper
        class="hidden md:block"
        :modules="modules"
        :spaceBetween="15"
        :slidesPerView="1"
        :navigation="true"
        :freeMode="true"
        :virtual="true"
        :breakpoints="{
          660: { slidesPerGroup: 2, slidesPerView: 3 },
          860: { slidesPerGroup: 3, slidesPerView: 4 },
          1060: { slidesPerGroup: 4, slidesPerView: 5 },
          1260: { slidesPerGroup: 5, slidesPerView: 6 },
        }"
      >
        <swiper-slide
          v-for="(item, index) in animeGroup"
          :key="index"
          :virtualIndex="index"
          class="min-w-[182px] max-w-[182px] imgPoster select-none min-h-[337px]"
        >
          <router-link :to="item?.url">
            <v-img
              :alt="item?.title"
              loading="lazy"
              class="rounded-[1.25rem] bg-second"
              :src="'/images/upload/' + item?.poster"
              :class="{ 'blur-sm': item?.nsfw == 18 }"
              width="182"
              height="273"
            >
              <template v-slot:placeholder>
                <v-sheet class="h-full">
                  <v-skeleton-loader
                    type="image"
                    class="rounded-[1.25rem] overflow-hidden h-full"
                    color="#00000000"
                  ></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
          </router-link>
          <router-link :to="item?.url">
            <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.title }}</div>
          </router-link>
          <div class="text-gray-200 text-xs">{{ formaterDate(item?.debut) }}</div>
        </swiper-slide>
      </swiper>
      <div class="md:hidden flex overflow-auto scrollbar-none">
        <div
          v-for="(item, index) in animeGroup"
          :key="index"
          class="min-w-[136px] max-w-[136px] imgPoster select-none mr-[12px]"
        >
          <router-link :to="item?.url">
            <v-img
              :alt="item?.title"
              loading="lazy"
              class="rounded-[1.25rem] bg-second"
              :class="{ 'blur-sm': item?.nsfw == 18 }"
              :src="'/images/thumbnail/' + item?.poster"
              width="136"
              height="204"
            >
            </v-img>
          </router-link>
          <router-link :to="item?.url">
            <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.title }}</div>
          </router-link>
          <div class="text-gray-200 text-xs">{{ formaterDate(item?.debut) }}</div>
        </div>
      </div>
    </div>
    <div class="mb-5" v-if="animeCharacters?.length">
      <div class="font-medium text-xl mb-4">{{ $t("infos.characters") }}</div>
      <swiper
        class="hidden md:block"
        :modules="modules"
        :spaceBetween="15"
        :slidesPerView="1"
        :navigation="true"
        :freeMode="true"
        :virtual="true"
        :breakpoints="{
          660: { slidesPerGroup: 2, slidesPerView: 3 },
          860: { slidesPerGroup: 3, slidesPerView: 4 },
          1060: { slidesPerGroup: 4, slidesPerView: 5 },
          1260: { slidesPerGroup: 5, slidesPerView: 6 },
        }"
      >
        <swiper-slide
          v-for="(item, index) in animeCharacters"
          :key="index"
          :virtualIndex="index"
          class="min-w-[175px] max-w-[175px] imgPoster select-none min-h-[321px]"
        >
          <v-img
            :alt="item?.title"
            loading="lazy"
            class="rounded-[1.25rem] bg-second"
            :src="'/images/characters/' + item?.photo"
            width="182"
            height="273"
          >
            <template v-slot:placeholder>
              <v-sheet class="h-full">
                <v-skeleton-loader
                  type="image"
                  class="rounded-[1.25rem] overflow-hidden h-full"
                  color="#00000000"
                ></v-skeleton-loader>
              </v-sheet>
            </template>
          </v-img>
          <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.nom }}</div>
        </swiper-slide>
      </swiper>
      <div class="md:hidden flex overflow-auto scrollbar-none">
        <div v-for="(item, index) in animeCharacters" :key="index">
          <div v-if="index <= 15" class="min-w-[131px] max-w-[131px] imgPoster select-none mr-[12px]">
            <v-img
              :alt="item?.title"
              loading="lazy"
              class="rounded-[1.25rem] bg-second"
              :src="'/images/characters/' + item?.photo"
              width="136"
              height="204"
            >
            </v-img>
            <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.nom }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5" v-if="animeSongs?.length">
      <div class="font-medium text-xl mb-4">{{ $t("infos.videos") }}</div>
      <swiper
        class="hidden md:block"
        :modules="modules"
        :spaceBetween="15"
        :slidesPerView="1"
        :navigation="true"
        :freeMode="true"
        :virtual="true"
        :breakpoints="{
          800: { slidesPerGroup: 2, slidesPerView: 3 },
          1050: { slidesPerGroup: 3, slidesPerView: 4 },
          1285: { slidesPerGroup: 5, slidesPerView: 5 },
        }"
      >
        <swiper-slide
          v-for="(item, index) in animeSongs"
          :key="index"
          :virtualIndex="index"
          class="min-w-[228px] max-w-[228px] min-h-[207px]"
        >
          <div
            class="h-[127px] items-center flex rounded-[1.25rem] overflow-hidden cursor-pointer bg-second"
            @click="
              videoEmbed = item?.lien;
              videoStart = item?.debut;
              toggleModal(1);
            "
          >
            <div class="h-[171px] flex justify-center items-center relative select-none imgVideo">
              <v-img
                :alt="item?.title"
                loading="lazy"
                class="rounded-[1.25rem]"
                :src="'https://img.youtube.com/vi/' + item?.lien + '/hqdefault.jpg'"
                width="228"
                height="171"
              >
                <template v-slot:placeholder>
                  <v-sheet class="h-full">
                    <v-skeleton-loader
                      type="image"
                      class="rounded-[1.25rem] overflow-hidden h-full"
                      color="#00000000"
                    ></v-skeleton-loader>
                  </v-sheet>
                </template>
              </v-img>
              <svg
                class="absolute outline-none"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 48 48"
              >
                <g>
                  <circle cx="25" cy="25" r="20" fill="#0f112b80"></circle>
                  <path
                    d="M24,47C11.31738,47,1,36.68262,1,24S11.31738,1,24,1s23,10.31738,23,23S36.68262,47,24,47z M24,5 C13.52344,5,5,13.52344,5,24s8.52344,19,19,19s19-8.52344,19-19S34.47656,5,24,5z"
                  ></path>
                  <path
                    d="M19,33c-0.16211,0-0.32422-0.03906-0.47168-0.11816C18.20312,32.70801,18,32.36914,18,32V16 c0-0.36914,0.20312-0.70801,0.52832-0.88184c0.32422-0.17383,0.71973-0.15332,1.02637,0.0498l12,8 C31.83301,23.35352,32,23.66602,32,24s-0.16699,0.64648-0.44531,0.83203l-12,8C19.3877,32.94336,19.19434,33,19,33z"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
          <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.song_name }}</div>
          <div class="text-gray-200 text-xs line-clamp-2">{{ item?.song_artist }}</div>
          <div class="text-gray-200 text-xs" v-if="item.song_type == 1">Opening {{ item.song_number }}</div>
          <div class="text-gray-200 text-xs" v-else-if="item.song_type == 2">Ending {{ item.song_number }}</div>
          <div class="text-gray-200 text-xs" v-else-if="item.song_type == 3">OST</div>
        </swiper-slide>
      </swiper>
      <div class="md:hidden flex overflow-auto scrollbar-none">
        <div v-for="(item, index) in animeSongs" :key="index" class="min-w-[228px] max-w-[228px] select-none mr-[15px]">
          <div
            class="h-[127px] items-center flex rounded-[1.25rem] overflow-hidden cursor-pointer bg-second"
            @click="
              videoEmbed = item?.lien;
              videoStart = item?.debut;
              toggleModal(1);
            "
          >
            <div class="h-[171px] flex justify-center items-center relative select-none imgVideo">
              <v-img
                :alt="item?.title"
                loading="lazy"
                class="rounded-[1.25rem]"
                :src="'https://img.youtube.com/vi/' + item?.lien + '/hqdefault.jpg'"
                width="228"
                height="171"
              >
              </v-img>
              <svg
                class="absolute outline-none"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 48 48"
              >
                <g>
                  <circle cx="25" cy="25" r="20" fill="#0f112b80"></circle>
                  <path
                    d="M24,47C11.31738,47,1,36.68262,1,24S11.31738,1,24,1s23,10.31738,23,23S36.68262,47,24,47z M24,5 C13.52344,5,5,13.52344,5,24s8.52344,19,19,19s19-8.52344,19-19S34.47656,5,24,5z"
                  ></path>
                  <path
                    d="M19,33c-0.16211,0-0.32422-0.03906-0.47168-0.11816C18.20312,32.70801,18,32.36914,18,32V16 c0-0.36914,0.20312-0.70801,0.52832-0.88184c0.32422-0.17383,0.71973-0.15332,1.02637,0.0498l12,8 C31.83301,23.35352,32,23.66602,32,24s-0.16699,0.64648-0.44531,0.83203l-12,8C19.3877,32.94336,19.19434,33,19,33z"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
          <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.song_name }}</div>
          <div class="text-gray-200 text-xs line-clamp-2">{{ item?.song_artist }}</div>
          <div class="text-gray-200 text-xs" v-if="item.song_type == 1">Opening {{ item.song_number }}</div>
          <div class="text-gray-200 text-xs" v-else-if="item.song_type == 2">Ending {{ item.song_number }}</div>
          <div class="text-gray-200 text-xs" v-else-if="item.song_type == 3">OST</div>
        </div>
      </div>
    </div>
    <div class="mb-10" v-if="animeScreenshots?.length">
      <div class="font-medium text-xl mb-4">{{ $t("infos.screenshots") }}</div>
      <swiper
        class="hidden md:block"
        :modules="modules"
        :spaceBetween="15"
        :slidesPerView="1"
        :navigation="true"
        :freeMode="true"
        :virtual="true"
        :breakpoints="{
          660: { slidesPerGroup: 2, slidesPerView: 3 },
          860: { slidesPerGroup: 3, slidesPerView: 4 },
          1060: { slidesPerGroup: 4, slidesPerView: 5 },
        }"
      >
        <swiper-slide
          v-for="(item, index) in animeScreenshots"
          :key="index"
          :virtualIndex="index"
          class="mgPoster select-none"
        >
          <v-img
            :class="{ 'blur-md': animeInfos?.nsfw == 18 }"
            @click="
              screenshots = item?.photo;
              screenshotsToggleModal(1);
            "
            :alt="animeInfos?.title"
            loading="lazy"
            class="rounded-[1.25rem] bg-second cursor-pointer"
            :src="'/images/screenshots/' + item?.photo"
          >
            <template v-slot:placeholder>
              <v-sheet class="h-full">
                <v-skeleton-loader
                  type="image"
                  class="rounded-[1.25rem] overflow-hidden h-full"
                  color="#00000000"
                ></v-skeleton-loader>
              </v-sheet>
            </template>
          </v-img>
        </swiper-slide>
      </swiper>
      <div class="md:hidden flex overflow-auto scrollbar-none">
        <div v-for="(item, index) in animeScreenshots" :key="index" class="select-none mr-[12px]">
          <v-img
            :class="{ 'blur-md': animeInfos?.nsfw == 18 }"
            @click="
              screenshots = item?.photo;
              screenshotsToggleModal(1);
            "
            :alt="animeInfos?.title"
            loading="lazy"
            class="rounded-[1.25rem] bg-second cursor-pointer"
            width="215"
            :src="'/images/screenshots/' + item?.photo"
          >
          </v-img>
        </div>
      </div>
    </div>
    <div class="mb-10" v-if="animeSimi?.length">
      <div class="font-medium text-xl mb-4">{{ $t("infos.similar_anime") }}</div>
      <swiper
        class="hidden md:block"
        :modules="modules"
        :spaceBetween="15"
        :slidesPerView="1"
        :navigation="true"
        :freeMode="true"
        :virtual="true"
        :breakpoints="{
          660: { slidesPerGroup: 2, slidesPerView: 3 },
          860: { slidesPerGroup: 3, slidesPerView: 4 },
          1060: { slidesPerGroup: 4, slidesPerView: 5 },
          1260: { slidesPerGroup: 5, slidesPerView: 6 },
        }"
      >
        <swiper-slide
          v-for="(item, index) in animeSimi"
          :key="index"
          :virtualIndex="index"
          class="min-w-[182px] max-w-[182px] imgPoster select-none min-h-[337px]"
        >
          <router-link :to="item?.url">
            <v-img
              :alt="item?.title"
              loading="lazy"
              class="rounded-[1.25rem] bg-second"
              :src="'/images/upload/' + item?.poster"
              :class="{ 'blur-sm': item?.nsfw == 18 }"
              width="182"
              height="273"
            >
              <template v-slot:placeholder>
                <v-sheet class="h-full">
                  <v-skeleton-loader
                    type="image"
                    class="rounded-[1.25rem] overflow-hidden h-full"
                    color="#00000000"
                  ></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
          </router-link>
          <router-link :to="item?.url">
            <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.title }}</div>
          </router-link>
          <div class="text-gray-200 text-xs">{{ formaterDate(item?.debut) }}</div>
        </swiper-slide>
      </swiper>
      <div class="md:hidden flex overflow-auto scrollbar-none">
        <div
          v-for="(item, index) in animeSimi"
          :key="index"
          class="min-w-[136px] max-w-[136px] imgPoster select-none mr-[12px]"
        >
          <router-link :to="item?.url">
            <v-img
              :alt="item?.title"
              loading="lazy"
              class="rounded-[1.25rem] bg-second"
              :src="'/images/thumbnail/' + item?.poster"
              :class="{ 'blur-sm': item?.nsfw == 18 }"
              width="136"
              height="204"
            >
            </v-img>
          </router-link>
          <router-link :to="item?.url">
            <div class="font-medium line-clamp-2 leading-5 my-1">{{ item?.title }}</div>
          </router-link>
          <div class="text-gray-200 text-xs">{{ formaterDate(item?.debut) }}</div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" timeout="3000" class="text-center" color="#008ca8">
      <p class="font-medium text-center">{{ $t("my_list.status_updated") }}</p>
    </v-snackbar>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed, defineProps } from "vue";
  import { useFetch } from "@vueuse/core";
  import ModalReuse2 from "@/components/ModalReuse2.vue";
  import ModalMenuAnime from "@/components/ModalMenuAnime.vue";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/virtual";
  import { Navigation, Virtual } from "swiper/modules";
  import { useAuthStore } from "@/stores/auth";
  import { useGlobalStore } from "@/stores/global";
  import { useI18n } from "vue-i18n";
  const props = defineProps({
    url: String,
  });
  const { t } = useI18n();
  const global = useGlobalStore();
  const auth = useAuthStore();
  const isLoading = ref(false);
  const modalActive = ref(null);
  const modules = [Navigation, Virtual];
  const animeInfos = ref(null);
  const animeGroup = ref(null);
  const animeSongs = ref(null);
  const videoEmbed = ref(null);
  const videoStart = ref(null);
  const animeCharacters = ref(null);
  const animeSimi = ref(null);
  const animeScreenshots = ref(null);
  const menuAnimeModalActive = ref(null);
  const snackbar = ref(null);
  const animeUser = ref(null);
  const rating = ref(null);
  const selectedLanguage = ref(localStorage.getItem("lang"));
  const screenshotsToggle = ref(false);
  const screenshots = ref(null);

  watch(rating, async (value) => {
    animeUser.value.score = value * 2;
    await useFetch("/api/anime/user/update/rating", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: animeInfos.value.id,
        value: value * 2,
      }),
    });
    snackbar.value = true;
  });
  watch(isLoading, (value) => {
    if (value === true) {
      global.pageLoad(1);
    }
  });
  onMounted(async () => {
    const { data: response } = await useFetch("/api/anime/infos", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: props.url,
      }),
    });
    const r = JSON.parse(response.value);
    animeInfos.value = r?.[0];

    const { data: responseUser } = await useFetch("/api/anime/user/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: r?.[0].id,
      }),
    });
    const r2 = JSON.parse(responseUser.value);
    if (r2?.[0]) {
      animeUser.value = r2[0];
    } else {
      animeUser.value = {};
    }
    const { data: responseGroup } = await useFetch("/api/anime/group", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: r?.[0].id,
      }),
    });
    animeGroup.value = JSON.parse(responseGroup.value);

    const { data: responseSongs } = await useFetch("/api/anime/songs", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: r?.[0].id,
      }),
    });
    animeSongs.value = JSON.parse(responseSongs.value);

    const { data: responseCharacters } = await useFetch("/api/anime/characters", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idMal: r?.[0].idMal,
      }),
    });
    animeCharacters.value = JSON.parse(responseCharacters.value);

    const { data: responseScreenshots } = await useFetch("/api/anime/screenshots", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: r?.[0].id,
      }),
    });
    animeScreenshots.value = JSON.parse(responseScreenshots.value);

    const { data: responseSimi } = await useFetch("/api/anime/simi", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: r?.[0].id,
        group: JSON.parse(responseGroup.value),
      }),
    });
    animeSimi.value = JSON.parse(responseSimi.value);
    global.pageLoad();
  });
  function formaterDate(dateStr) {
    if (!dateStr) {
      return computed(() => t("infos.unknown"));
    }
    let date = new Date(dateStr);
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options = { timeZone: timeZone, year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat(undefined, options).format(date);
  }
  async function menuAnime(value) {
    if (animeUser.value?.status === value) {
      animeUser.value.status = 0;
    } else {
      animeUser.value.status = value;
    }
    await useFetch("/api/anime/user/update/menu", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: animeInfos.value.id,
        value: animeUser.value.status,
      }),
    });
    snackbar.value = true;
  }
  async function menuFavAnime() {
    if (animeUser.value?.favorite === 1) {
      animeUser.value.favorite = 0;
    } else {
      animeUser.value.favorite = 1;
    }
    await useFetch("/api/anime/user/update/fav", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: animeInfos.value.id,
        value: animeUser.value.favorite,
      }),
    });
    snackbar.value = true;
  }
  const toggleModal = (toggle) => {
    if (toggle) {
      modalActive.value = true;
    } else {
      modalActive.value = false;
    }
  };
  const screenshotsToggleModal = (toggle) => {
    if (toggle) {
      screenshotsToggle.value = true;
    } else {
      screenshotsToggle.value = false;
    }
  };
  const menuAnimeToggleModal = (toggle) => {
    if (auth.user.role == "guest") {
      global.signUp = true;
      return;
    }

    if (toggle) {
      menuAnimeModalActive.value = true;
    } else {
      menuAnimeModalActive.value = false;
    }
  };
</script>

<style scoped>
  @media (max-width: 767px) {
    .actionBtn {
      width: 100%;
      height: 50px;
      right: 0;
      bottom: 33px;
      position: fixed;
      z-index: 40;
      display: flex;
      justify-content: center;
    }
  }
  :deep(.v-sheet) {
    background: none;
  }
  :deep(.swiper-button-prev),
  :deep(.swiper-button-next) {
    color: white;
    font-size: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
    background: linear-gradient(45deg, #60a5fa, #a78bfa, #60a5fa);
    transition: 0.3s;
  }
  :deep(.swiper-button-next:hover) {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(96, 165, 250, 0.5);
  }
  :deep(.swiper-button-prev::before),
  :deep(.swiper-button-next::before) {
    content: "";
    position: absolute;
    inset: -3px;
    background: linear-gradient(45deg, #60a5fa, #a78bfa, #60a5fa);
    border-radius: 50%;
    z-index: -1;
    opacity: 0.5;
  }
  :deep(.swiper-button-prev::after),
  :deep(.swiper-button-next::after) {
    font-size: 27px;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    :deep(.swiper-button-prev),
    :deep(.swiper-button-next) {
      display: none;
    }
  }
  .anime-genres .anime-genre {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
    padding-right: 12px;
    overflow: hidden;
    border-radius: 8px;
    white-space: nowrap;
    margin-right: 10px;
  }
  :deep(.imgPoster .v-responsive__sizer),
  :deep(.imgPoster .v-skeleton-loader__image) {
    padding-bottom: 150%;
  }
  :deep(.imgVideo .v-responsive__sizer),
  :deep(.imgVideo .v-skeleton-loader__image) {
    padding-bottom: 56.17978%;
  }
</style>
