<template>
  <div id="draggable" v-if="auth.user.role == 'admin'" class="invisible">
    <div class="flex">
      <span>Songs Number:</span>
      <v-text-field type="number" v-model="settings.n" />
      <span>/</span>
      <v-text-field type="number" v-model="settings.songsNumber" />
    </div>
    <div class="flex">
      <span>Difficulty:</span>
      <v-text-field type="number" v-model="settings.difficulty" />
    </div>
    <div class="flex">
      <span>Watched:</span>
      <v-checkbox type="checkbox" v-model="settings.songsSelection" />
    </div>
    <div class="flex">
      <span>Admin access:</span>
      <v-checkbox type="checkbox" v-model="settings.access" />
    </div>
    <div class="flex">
      <span>Next song:</span>
      <v-autocomplete
        v-model="settings.nextSong"
        :items="animesSongs"
        chips
        closable-chips
        color="blue-grey-lighten-2"
        item-title="name"
        item-value="id"
        label="Select"
        auto-select-first
        multiple
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :prepend-avatar="'/images/thumbnail/' + item?.raw?.poster"
            :text="item.raw.short + ' - ' + item?.raw?.song_type + ' ' + item?.raw?.song_number"
          ></v-chip>
        </template>

        <template v-slot:item="{ props, item }">
          <v-list-item
            class="bg-second text-white"
            v-bind="props"
            :prepend-avatar="'/images/thumbnail/' + item?.raw?.poster"
            :title="item?.raw?.name"
            :subtitle="item?.raw?.song_type + ' ' + item?.raw?.song_number + ' - ' + item?.raw?.song_name"
          ></v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <button @click="setSongsNumber">Set</button>
  </div>
  <img
    src="https://baranimes.com/images/global/favicon_no_bg.webp"
    class="small pointer-events-none"
    id="logo"
    alt="Baranimes"
  />
  <div class="main relative" id="main">
    <header class="header-section-main">
      <div class="custom-container">
        <div class="header-section-inner">
          <div class="header-logo flex 2xl:block">
            <router-link to="/testhome">
              <img src="https://baranimes.com/images/global/favicon_no_bg.webp" class="cursor-scale small" alt="logo" />
            </router-link>
            <div class="flex 2xl:mt-4 items-center uppercase ml-[-10px]" id="back" @click="retour">
              <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" width="60" height="40" viewBox="0 0 24 24">
                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                  <path d="m13 19l-6-7l6-7" />
                  <path d="m17 19l-6-7l6-7" opacity=".5" />
                </g>
              </svg>
              <span class="text-[15px] w-0 whitespace-nowrap ml-[-21px] cursor-pointer">⬩ Retour</span>
            </div>
            <div class="banner-left-vertical-main hidden 2xl:flex" id="banner-left-vertical-main">
              <h6>BARANIMES</h6>
              <img src="https://www.yudiz.com/codepen/valorant-characters/gl-logo-first.svg" class="gl-logo-img" />
            </div>
          </div>

          <div class="header-menu hidden lg:block">
            <ul class="hidden lg:flex">
              <li
                v-if="$route.path != '/testhome' && $route.path != '/testroom'"
                class="cursor-scale play"
                @click="play"
              >
                Jouer
              </li>
              <li v-if="$route.path != '/testhome' && $route.path != '/testroom'" class="cursor-scale mx-[-5px]">/</li>

              <v-menu
                open-on-hover
                open-on-click="false"
                open-delay="0"
                close-delay="0"
                transition="fade"
                location
                content-class="pointer-events-none"
              >
                <template v-slot:activator="{ props }">
                  <router-link to="/testhome" v-bind="props">
                    <li class="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                        <g fill="none">
                          <path
                            d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"
                          />
                          <path
                            fill="currentColor"
                            d="M5.5 16.5a2 2 0 1 1 0 4a2 2 0 0 1 0-4m6.5 0a2 2 0 1 1 0 4a2 2 0 0 1 0-4m6.5 0a2 2 0 1 1 0 4a2 2 0 0 1 0-4M5.5 10a2 2 0 1 1 0 4a2 2 0 0 1 0-4m6.5 0a2 2 0 1 1 0 4a2 2 0 0 1 0-4m6.5 0a2 2 0 1 1 0 4a2 2 0 0 1 0-4m-13-6.5a2 2 0 1 1 0 4a2 2 0 0 1 0-4m6.5 0a2 2 0 1 1 0 4a2 2 0 0 1 0-4m6.5 0a2 2 0 1 1 0 4a2 2 0 0 1 0-4"
                          />
                        </g>
                      </svg>
                    </li>
                  </router-link>
                </template>
                <v-list
                  class="mt-[64px] pt-4 p-0 pointer-events-auto w-[180px]"
                  style="background: transparent; box-shadow: none; overflow: visible"
                >
                  <div
                    class="rounded-2xl overflow-hidden text-[#fff] bg-[#405588]"
                    style="box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25)"
                  >
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M6 19h3v-5q0-.425.288-.712T10 13h4q.425 0 .713.288T15 14v5h3v-9l-6-4.5L6 10zm-2 0v-9q0-.475.213-.9t.587-.7l6-4.5q.525-.4 1.2-.4t1.2.4l6 4.5q.375.275.588.7T20 10v9q0 .825-.588 1.413T18 21h-4q-.425 0-.712-.288T13 20v-5h-2v5q0 .425-.288.713T10 21H6q-.825 0-1.412-.587T4 19m8-6.75"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Lobby</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 1024 1024"
                          >
                            <path
                              fill="currentColor"
                              d="M870 126H663.8c-17.4 0-32.9 11.9-37 29.3C614.3 208.1 567 246 512 246s-102.3-37.9-114.8-90.7a37.93 37.93 0 0 0-37-29.3H154a44 44 0 0 0-44 44v252a44 44 0 0 0 44 44h75v388a44 44 0 0 0 44 44h478a44 44 0 0 0 44-44V466h75a44 44 0 0 0 44-44V170a44 44 0 0 0-44-44m-28 268H723v432H301V394H182V198h153.3c28.2 71.2 97.5 120 176.7 120s148.5-48.8 176.7-120H842z"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Avatar</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="m12 14.475l1.9 1.15q.275.175.55-.012t.2-.513l-.5-2.175l1.7-1.475q.25-.225.15-.537t-.45-.338l-2.225-.175l-.875-2.05q-.125-.3-.45-.3t-.45.3l-.875 2.05l-2.225.175q-.35.025-.45.338t.15.537l1.7 1.475l-.5 2.175q-.075.325.2.513t.55.012zM8.65 20H6q-.825 0-1.412-.587T4 18v-2.65L2.075 13.4q-.275-.3-.425-.662T1.5 12t.15-.737t.425-.663L4 8.65V6q0-.825.588-1.412T6 4h2.65l1.95-1.925q.3-.275.663-.425T12 1.5t.738.15t.662.425L15.35 4H18q.825 0 1.413.588T20 6v2.65l1.925 1.95q.275.3.425.663t.15.737t-.15.738t-.425.662L20 15.35V18q0 .825-.587 1.413T18 20h-2.65l-1.95 1.925q-.3.275-.662.425T12 22.5t-.737-.15t-.663-.425zm.85-2l2.5 2.5l2.5-2.5H18v-3.5l2.5-2.5L18 9.5V6h-3.5L12 3.5L9.5 6H6v3.5L3.5 12L6 14.5V18zm2.5-6"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Battlepass</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none" stroke="currentColor" stroke-width="1.5">
                              <path
                                stroke-linecap="round"
                                d="M16 22v-9c0-1.414 0-2.121-.44-2.56C15.122 10 14.415 10 13 10h-2c-1.414 0-2.121 0-2.56.44C8 10.878 8 11.585 8 13v9m0 0c0-1.414 0-2.121-.44-2.56C7.122 19 6.415 19 5 19s-2.121 0-2.56.44C2 19.878 2 20.585 2 22m20 0v-3c0-1.414 0-2.121-.44-2.56C21.122 16 20.415 16 19 16s-2.121 0-2.56.44C16 16.878 16 17.585 16 19v3"
                              />
                              <path
                                d="M11.146 3.023C11.526 2.34 11.716 2 12 2s.474.34.854 1.023l.098.176c.108.194.162.29.246.354c.085.064.19.088.4.135l.19.044c.738.167 1.107.25 1.195.532s-.164.577-.667 1.165l-.13.152c-.143.167-.215.25-.247.354s-.021.215 0 .438l.02.203c.076.785.114 1.178-.115 1.352c-.23.175-.576.015-1.267-.303l-.178-.082c-.197-.09-.295-.136-.399-.136s-.202.046-.399.136l-.178.082c-.691.318-1.037.478-1.267.303c-.23-.174-.191-.567-.115-1.352l.02-.203c.021-.223.032-.334 0-.438s-.104-.187-.247-.354l-.13-.152c-.503-.588-.755-.882-.667-1.165c.088-.282.457-.365 1.195-.532l.19-.044c.21-.047.315-.07.4-.135c.084-.064.138-.16.246-.354z"
                              />
                            </g>
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Classement</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M7 22q-.825 0-1.412-.587T5 20t.588-1.412T7 18t1.413.588T9 20t-.587 1.413T7 22m10 0q-.825 0-1.412-.587T15 20t.588-1.412T17 18t1.413.588T19 20t-.587 1.413T17 22M6.15 6l2.4 5h7l2.75-5zM5.2 4h14.75q.575 0 .875.513t.025 1.037l-3.55 6.4q-.275.5-.737.775T15.55 13H8.1L7 15h11q.425 0 .713.288T19 16t-.288.713T18 17H7q-1.125 0-1.7-.987t-.05-1.963L6.6 11.6L3 4H2q-.425 0-.712-.288T1 3t.288-.712T2 2h1.625q.275 0 .525.15t.375.425zm3.35 7h7z"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Boutique</v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </div>
                </v-list>
              </v-menu>
              <li class="ml-[-4px]">
                <v-dialog max-width="50%" content-class="top-[50px]">
                  <template v-slot:activator="{ props: activatorProps }">
                    <svg
                      v-bind="activatorProps"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l5.6 5.6q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-5.6-5.6q-.75.6-1.725.95T9.5 16m0-2q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"
                      />
                    </svg>
                  </template>
                  <template v-slot:default="{ isActive }">
                    <v-card class="bg-1" style="border-radius: 1.25rem; background: #223255">
                      <v-text-field
                        :label="$t('header.menu.search')"
                        prepend-inner-icon="mdi-magnify"
                        id="searchAnimeHeader"
                        autocomplete="off"
                        clearable
                        variant="solo"
                        density="comfortable"
                        hide-details
                        single-line
                        rounded="2xl"
                        type="search"
                        bg-color="#344673"
                        @input="search"
                        v-model="query"
                        class="text-white w-full p-6"
                      ></v-text-field>
                      <span text="Close Dialog" class="invisible" @click="isActive.value = false"></span>
                      <p class="text-center text-2xl uppercase font-medium mt-6 mb-4">
                        {{ visibleAnime?.length }} resultats
                      </p>
                      <v-virtual-scroll
                        :class="{ hidden: visibleAnime?.length === 0 }"
                        :items="visibleAnime"
                        height="420"
                        :key="visibleAnime"
                        item-height="75"
                        id="animeListHeader"
                      >
                        <template v-slot:default="{ item }">
                          <v-sheet
                            class="min-w-[168px] max-w-[168px] imgPoster select-none bg-transparent m-2 mb-6"
                            @click="open(item.url)"
                          >
                            <v-img
                              :alt="item?.title"
                              loading="lazy"
                              class="rounded-xl bg-second"
                              :src="'/images/upload/' + item?.image"
                              width="168"
                              height="250"
                            >
                              <template v-slot:placeholder>
                                <v-sheet class="h-full">
                                  <v-skeleton-loader
                                    type="image"
                                    class="rounded-xl overflow-hidden shadow-3xl h-[250px]"
                                    color="#00000000"
                                  ></v-skeleton-loader>
                                </v-sheet>
                              </template>
                            </v-img>
                            <div class="font-medium line-clamp-2 leading-5 my-1 text-white">{{ item?.title }}</div>
                            <div class="text-gray-200 text-xs">{{ formaterDate(item?.debut) }}</div>
                          </v-sheet>
                        </template>
                      </v-virtual-scroll>
                    </v-card>
                  </template>
                </v-dialog>
              </li>
              <li>
                <svg
                  v-if="!fullScreen"
                  @click="openFullscreen"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M13 3.5v3a.5.5 0 0 1-1 0V4.71L9.85 6.86a.5.5 0 0 1-.707-.707l2.15-2.15h-1.79a.5.5 0 0 1 0-1h3a.5.5 0 0 1 .351.144l.004.004a.5.5 0 0 1 .144.348v.004zM3.5 9a.5.5 0 0 1 .5.5v1.79l2.15-2.15a.5.5 0 0 1 .707.707l-2.15 2.15h1.79a.5.5 0 0 1 0 1H3.494a.5.5 0 0 1-.497-.499v-3a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M0 4.8c0-1.68 0-2.52.327-3.16A3.02 3.02 0 0 1 1.637.33c.642-.327 1.48-.327 3.16-.327h6.4c1.68 0 2.52 0 3.16.327a3.02 3.02 0 0 1 1.31 1.31c.327.642.327 1.48.327 3.16v6.4c0 1.68 0 2.52-.327 3.16a3 3 0 0 1-1.31 1.31c-.642.327-1.48.327-3.16.327h-6.4c-1.68 0-2.52 0-3.16-.327a3 3 0 0 1-1.31-1.31C0 13.718 0 12.88 0 11.2zM4.8 1h6.4c.857 0 1.44 0 1.89.038c.438.035.663.1.819.18c.376.192.682.498.874.874c.08.156.145.38.18.819c.037.45.038 1.03.038 1.89v6.4c0 .857-.001 1.44-.038 1.89c-.036.438-.101.663-.18.819a2 2 0 0 1-.874.874c-.156.08-.381.145-.819.18c-.45.036-1.03.037-1.89.037H4.8c-.857 0-1.44 0-1.89-.037c-.438-.036-.663-.101-.819-.18a2 2 0 0 1-.874-.874c-.08-.156-.145-.381-.18-.82C1 12.64.999 12.06.999 11.2V4.8c0-.856.001-1.44.038-1.89c.036-.437.101-.662.18-.818c.192-.376.498-.682.874-.874c.156-.08.381-.145.819-.18C3.36 1 3.94 1 4.8 1"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  v-else
                  @click="closeFullscreen"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M8 1.5a.5.5 0 0 0-.5-.5H4.2c-1.12 0-1.68 0-2.11.218a2 2 0 0 0-.874.874c-.218.428-.218.988-.218 2.11v3.3a.5.5 0 0 0 1 0v-3.3c0-.577 0-.949.024-1.23c.022-.272.06-.372.085-.422c.096-.188.249-.341.437-.437c.05-.025.15-.063.422-.085c.283-.023.656-.024 1.23-.024h3.3a.5.5 0 0 0 .5-.5zm7 10.3V8.5a.5.5 0 0 0-1 0v3.3c0 .577 0 .949-.024 1.23c-.022.272-.06.372-.085.422a1 1 0 0 1-.437.437c-.05.025-.15.063-.422.085c-.283.023-.656.024-1.23.024h-3.3a.5.5 0 0 0 0 1h3.3c1.12 0 1.68 0 2.11-.218c.376-.192.682-.498.874-.874c.218-.428.218-.988.218-2.11zM6.85 9.15a.5.5 0 0 1 .147.35v3.003a.5.5 0 0 1-1 0v-1.79l-4.15 4.15a.5.5 0 0 1-.707-.707l4.15-4.15H3.5a.5.5 0 0 1 0-1h3a.5.5 0 0 1 .191.038q.09.036.162.11zM10.7 6l4.15-4.15a.5.5 0 0 0-.707-.707l-4.15 4.15v-1.79a.5.5 0 0 0-1 0v3.003a.5.5 0 0 0 .309.46a.5.5 0 0 0 .19.037h3a.5.5 0 0 0 0-1h-1.79z"
                  />
                </svg>
              </li>
              <li class="cursor-scale mx-[-5px]">/</li>
              <li class="flex coins">
                <div class="flex items-center mr-[30px]">
                  <img loading="lazy" src="https://baranimes.com/images/global/goldencoins.webp" class="h-[22px]" />
                  <span class="text-[14px] mr-5">2 000</span>
                  <button class="more bg-linear"><span class="line1"></span><span class="line2"></span></button>
                </div>
                <div class="flex items-center">
                  <img loading="lazy" src="https://baranimes.com/images/global/coins.webp" class="h-[22px]" />
                  <span class="text-[14px]">120 000</span>
                </div>
              </li>
              <v-menu
                open-on-hover
                open-on-click="false"
                open-delay="0"
                close-delay="0"
                transition="fade"
                location
                content-class="pointer-events-none"
              >
                <template v-slot:activator="{ props }">
                  <router-link to="/testhome" class="z-50" v-bind="props">
                    <li class="flex items-center">
                      <v-avatar
                        size="40"
                        image="https://p16-sign-useast2a.tiktokcdn.com/tos-useast2a-avt-0068-euttp/94ec360aa5a2570f55dbab82fafee398~c5_1080x1080.jpeg?lk3s=a5d48078&nonce=40507&refresh_token=83e060dc003fa147c87cc734f3f172e8&x-expires=1736114400&x-signature=dJ1L0YYQxA0EdsMtD7NuKs1ne2o%3D&shp=a5d48078&shcp=81f88b70"
                      ></v-avatar>
                      <div class="ml-3">
                        <p>MisterClems</p>
                        <div class="flex items-center">
                          <p>lvl 84</p>
                          <span
                            style="
                              background: linear-gradient(90deg, rgb(245 79 103) 20%, rgb(95 109 150) 0%);
                              height: 8px;
                              width: 50px;
                              margin-left: 4px;
                              border-radius: 100px;
                            "
                          ></span>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M11.475 14.475L7.85 10.85q-.075-.075-.112-.162T7.7 10.5q0-.2.138-.35T8.2 10h7.6q.225 0 .363.15t.137.35q0 .05-.15.35l-3.625 3.625q-.125.125-.25.175T12 14.7t-.275-.05t-.25-.175"
                        />
                      </svg>
                    </li>
                  </router-link>
                </template>
                <v-list
                  class="mt-[68px] pt-3 p-0 pointer-events-auto w-[195px]"
                  style="background: transparent; box-shadow: none; overflow: visible"
                >
                  <div
                    class="rounded-2xl overflow-hidden text-[#fff] bg-[#3c5082]"
                    style="box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25)"
                  >
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5.85 17.1q1.275-.975 2.85-1.537T12 15t3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4T6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5t1.013-2.488T12 6t2.488 1.013T15.5 9.5t-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Profil</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M8.5 11.5h7v-1h-7zm0-4h7v-1h-7zm10.412 12.437l-3.416-4.43q-.367-.478-.877-.742q-.51-.265-1.119-.265H5V4.616q0-.691.463-1.153T6.616 3h10.769q.69 0 1.153.463T19 4.616v14.769q0 .144-.022.285q-.022.142-.066.267M6.616 21q-.691 0-1.153-.462T5 19.385V15.5h8.5q.366 0 .674.16q.309.161.536.457l3.527 4.616q-.183.127-.397.197t-.456.07z"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Mes listes</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zm2.8-6.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-semibold">Paramètres</v-list-item-title>
                      </v-list-item>
                    </router-link>
                    <v-list-item class="hover:bg-[#4b6198]" v-if="auth.user.role == 'admin'" @click="toggleOutils">
                      <template v-slot:prepend>
                        <svg
                          class="mr-4 opacity-80"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zm2.8-6.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5"
                          />
                        </svg>
                      </template>
                      <v-list-item-title class="font-semibold">Outils admin</v-list-item-title>
                    </v-list-item>
                    <router-link to="/testhome">
                      <v-list-item class="hover:bg-[#4b6198]">
                        <template v-slot:prepend>
                          <svg
                            class="mr-4 opacity-80 text-[#f54f67]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M4 5.4A1.4 1.4 0 0 1 5.4 4h6.2A1.4 1.4 0 0 1 13 5.4V8a1 1 0 1 0 2 0V5.4A3.4 3.4 0 0 0 11.6 2H5.4A3.4 3.4 0 0 0 2 5.4v13.2A3.4 3.4 0 0 0 5.4 22h6.2a3.4 3.4 0 0 0 3.4-3.4V16a1 1 0 1 0-2 0v2.6a1.4 1.4 0 0 1-1.4 1.4H5.4A1.4 1.4 0 0 1 4 18.6z"
                            />
                            <path
                              fill="currentColor"
                              d="M17.293 8.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414L18.586 13H7a1 1 0 1 1 0-2h11.586l-1.293-1.293a1 1 0 0 1 0-1.414"
                            />
                          </svg>
                        </template>
                        <v-list-item-title class="font-bold text-[#f54f67]">Se déconnecter</v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </div>
                </v-list>
              </v-menu>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <div class="sidebar-right hidden xl:block"></div>
  </div>

  <BottomSheet
    :snapPoints="[maxHeight / 2]"
    :blocking="false"
    ref="bottomSheet"
    @closed="detectclose"
    :default-snap-point="maxHeight"
  >
    <Child v-if="showBottomSheetToggle" ref="child" :url="url" />
  </BottomSheet>
</template>
<script setup>
  import { ref, reactive, onMounted } from "vue";
  import { useGlobalStore } from "@/stores/global";
  import { useRouter } from "vue-router";
  import { useJoinRoomStore } from "@/stores/joinRoom";
  import { useAuthStore } from "@/stores/auth";
  import { useFetch } from "@vueuse/core";
  import Child from "./TestInfoAnime.vue";
  import BottomSheet from "@douxcode/vue-spring-bottom-sheet";
  import "@douxcode/vue-spring-bottom-sheet/dist/style.css";
  const bottomSheet = ref(null);
  const maxHeight = ref(window.innerHeight);
  let stopPropa = true;
  let backdrop;

  const url = ref(null);
  const auth = useAuthStore();
  const joinRoom = useJoinRoomStore();
  const socket = joinRoom.socket;
  const global = useGlobalStore();
  const router = useRouter();
  const settings = reactive({
    n: 1,
    songsNumber: 20,
    difficulty: 7,
    songsSelection: false,
    access: false,
    nextSong: [],
  });
  const query = ref("");
  const visibleAnime = ref([]);
  const fullScreen = ref(false);
  const animesSongs = ref([]);
  const showBottomSheetToggle = ref(false);
  onMounted(async () => {
    document.getElementsByTagName("html")[0].classList.remove("prod");
    document.getElementsByTagName("html")[0].classList.add("dev");
    const draggable = document.getElementById("draggable");
    if (draggable) {
      const { data: response } = await useFetch("/api/tiktok/get", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      animesSongs.value = JSON.parse(response.value);
      var isMouseDown,
        initX,
        initY,
        height = draggable.offsetHeight,
        width = draggable.offsetWidth;

      draggable.addEventListener("mousedown", function (e) {
        isMouseDown = true;
        document.body.classList.add("no-select");
        initX = e.offsetX;
        initY = e.offsetY;
      });

      document.addEventListener("mousemove", function (e) {
        if (isMouseDown) {
          var cx = e.clientX - initX,
            cy = e.clientY - initY;
          if (cx < 0) {
            cx = 0;
          }
          if (cy < 0) {
            cy = 0;
          }
          if (window.innerWidth - e.clientX + initX < width) {
            cx = window.innerWidth - width;
          }
          if (e.clientY > window.innerHeight - height + initY) {
            cy = window.innerHeight - height;
          }
          draggable.style.left = cx + "px";
          draggable.style.top = cy + "px";
        }
      });

      draggable.addEventListener("mouseup", function () {
        isMouseDown = false;
        document.body.classList.remove("no-select");
      });
    }
    backdrop = document.querySelector("[data-vsbs-backdrop]");
    // data.style.visibility = "hidden";

    // showBottomSheetToggle.value = true;
    // bottomSheet.value.open();

    // setTimeout(function () {
    //   bottomSheet.value.close();
    //   setTimeout(function () {
    //     data.style.visibility = "visible";
    //     showBottomSheetToggle.value = false;
    //   }, 400);
    // }, 400);
  });
  const open = (link) => {
    // document.querySelector("[data-vsbs-scroll]").scrollTop = 0;
    global.back = true;
    url.value = link;
    document.getElementById("back").classList.add("active");
    backdrop.style.display = "block";
    setTimeout(function () {
      backdrop.style.opacity = 1;
    }, 1);
    document.getElementById("main").style.zIndex = 3001;
    showBottomSheetToggle.value = true;
    bottomSheet.value.open();
  };
  const close = () => {
    stopPropa = false;
    document.getElementById("back").classList.remove("active");
    bottomSheet.value.close();
    backdrop.style.opacity = 0;
    setTimeout(function () {
      document.getElementById("main").style = "";
      backdrop.style.display = "none";
      showBottomSheetToggle.value = false;
      stopPropa = true;
    }, 150);
  };
  function detectclose() {
    if (stopPropa && showBottomSheetToggle.value) {
      console.log("close");
      close();
    }
  }
  function retour() {
    console.log(new Date().getTime());
    global.back = false;
    if (showBottomSheetToggle.value) {
      close();
      return;
    }
    if (location.pathname == "/fr/testhome") {
      if (typeof global.pageTransition === "string" && global.step == 0) {
        document.getElementById("controller-box-second").classList.remove("active");
        console.log(global.pageTransition);
        setTimeout(function () {
          router.push({ name: global.pageTransition });
        }, 100);
      } else {
        if (global.step == 1) {
          global.step = 0;
          document.getElementById("controller-box-second").classList.remove("next");
          document.getElementById("controller-box-second-create").classList.remove("active");
        } else if (global.step == 2) {
          global.step = 1;
          document.getElementById("controller-box-second-create").classList.remove("next");
          document.getElementById("controller-box-second-settings").classList.remove("active");
        } else {
          document.getElementById("back").classList.remove("active");
          document.getElementById("banner-content-main").classList.remove("active");
          document.getElementById("controller-box-second").classList.remove("active");
          setTimeout(function () {
            document.getElementById("controller-box-second").classList.remove("options");
          }, 150);
          setTimeout(function () {
            document.getElementById("section-home").classList.remove("active");
            // document.getElementById("banner-left-vertical-main").classList.remove("active");
          }, 100);
        }
      }
    } else if (location.pathname == "/fr/testroom") {
      document.getElementById("router").classList.add("test");
      document.getElementById("router").classList.add("active2");
      global.pageTransition = 1;
      setTimeout(function () {
        router.push({ name: "testhome" });
      }, 100);
    } else if (location.pathname == "/fr/testbattle-pass" || location.pathname == "/fr/teststore") {
      global.pageTransition = 2;
      document.getElementsByTagName("body")[0].classList.add("maxHeight");
      document.getElementById("router").classList.add("translateY");
      document.getElementById("router").classList.add("test");
      setTimeout(function () {
        router.push({ name: "testhome" });
        document.getElementById("logo").classList.add("active");
      }, 100);
    } else {
      router.push({ name: "testhome" });
    }
  }
  function play() {
    if (location.pathname == "/fr/testbattle-pass" || location.pathname == "/fr/teststore") {
      global.pageTransition = location.pathname.replace("/en/", "").replace("/fr/", "").replace("-", "");
      global.step = 0;
      document.getElementsByTagName("body")[0].classList.add("maxHeight");
      document.getElementById("router").classList.add("translateY");
      document.getElementById("router").classList.add("test");
      setTimeout(function () {
        router.push({ name: "testhome" });
        document.getElementById("logo").classList.add("active");
      }, 100);
    }
  }
  const search = () => {
    socket.emit("search", query.value);
  };
  const setSongsNumber = () => {
    if (settings.nextSong.length) {
      console.log(settings.nextSong.length);
      for (let i = 0; i < settings.n; i++) {
        settings.nextSong.unshift({});
      }
    }
    socket.emit("settings", settings);
  };
  socket.on("search", function (response) {
    visibleAnime.value = response;
  });
  function formaterDate(dateStr) {
    if (!dateStr) {
      return "Unknown";
    }
    let date = new Date(dateStr);
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options = { timeZone: timeZone, year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat(undefined, options).format(date);
  }
  var elem = document.documentElement;

  function openFullscreen() {
    fullScreen.value = true;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    fullScreen.value = false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
  function toggleOutils() {
    document.getElementById("draggable").classList.toggle("invisible");
  }
</script>
<style scoped>
  @keyframes slideInFromTop {
    0% {
      transform: translate(-50%, -200%);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, -150%);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -150%);
      opacity: 0;
    }
  }
  .more {
    width: 22px;
    height: 22px;
    transition: 300ms;
    cursor: pointer;
    border-radius: 9999px;
    position: absolute;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line1,
  .line2 {
    width: 12px;
    height: 1.5px;
    position: absolute;
    background: white;
    border-radius: 9999px;
  }
  .line2 {
    rotate: 90deg;
  }
  .coins > div {
    border: 1px solid;
    padding: 4px 24px;
    padding-left: 35px;
    border-radius: 100px;
    position: relative;
  }
  .coins > div > img {
    position: absolute;
    left: -8px;
    height: 36px;
    width: 36px;
    filter: contrast(1.3) brightness(0.9);
  }
  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 150px;
    opacity: 0;
    transition: 0.15s;
  }
  #logo.active {
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: 0.4s;
  }
  /* -- Header Section CSS Start -- */

  .custom-container {
    padding: 0 70px 0 32px;
    margin: 0 auto;
  }
  h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 18.48px;
    color: #c7cef8;
  }
  .banner-loop-third .banner-left-vertical-main h6 {
    color: var(--text-color-14);
  }
  .banner-loop-second .banner-left-vertical-main h6 {
    color: var(--text-color-7);
  }
  .banner-left-vertical-main h6 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  .banner-left-vertical-main img {
    width: 70px;
    height: 70px;
  }
  .banner-left-vertical-main {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .banner-left-vertical-main {
    transition: transform 0.3s, opacity 0.3s;
  }
  .banner-left-vertical-main.active {
    transform: translate(-100%);
  }
  .banner-left-vertical-main.active {
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0;
  }
  #back {
    transition: 0.4s;
    opacity: 0;
    pointer-events: none;
    color: #c7cef8;
  }
  #back.active {
    transition: 0.2s;
    opacity: 0.75;
    pointer-events: all;
  }
  .header-section-main {
    position: fixed;
    margin-top: 40px;
    width: 100%;
    z-index: 99;
    height: 0;
    /* box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15); */
  }
  /* .header-section-main::before {
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: rgba(25, 30, 37, 0.8);
    border-radius: 0 0 12px 12px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 0.1s linear;
    z-index: -1;
  } */
  .header-section-inner {
    display: flex;
    justify-content: space-between;
    height: 0;
  }
  .header-logo {
    flex-shrink: 0;
    user-select: none;
    font-weight: 600;
  }
  .header-logo .banner-left-vertical-main {
    justify-content: center;
    height: calc(100svh - 196px);
  }
  .header-logo img {
    width: 60px;
    height: 60px;
    backdrop-filter: blur(10px);
  }
  .header-menu ul li svg {
    cursor: pointer;
    /* margin-top: 4px; */
  }
  .header-menu ul {
    gap: 25px;
    justify-content: end;
    list-style: none;
    align-items: center;
    font-weight: 600;
    border-radius: 100px;
    padding: 10px 30px;
    backdrop-filter: blur(10px);
    margin-top: -10px;
    /* color: #fff; */
  }

  .header-menu ul li a {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    font-style: normal;
    color: #c7cef8;
    display: inline-block;
    transition: 0.3s;
    -webkit-transition: 0.3s;
  }
  .header-menu ul li a:hover,
  .header-menu ul li.active a {
    color: #c7cef8;
  }

  .small {
    border: 1px solid rgb(199 206 248 / 50%);
    border-radius: 100%;
  }

  :deep(.rounded-2xl) {
    box-shadow: none;
  }

  .sidebar-right:hover,
  nav.sidebar-right.expanded {
    width: 400px;
    overflow: visible;
  }

  .sidebar-right {
    background: #10101ccc;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    right: 0;
    width: 56px;
    overflow: hidden;
    transition: width 0.2s ease;
    z-index: 1000;
  }
  .play {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background: linear-gradient(
      -60deg,
      #ff8d9e 0%,
      #ff8d9e 10%,
      #f54f67 25%,
      #f54f67 30%,
      #ff8d9e 50%,
      #f54f67 80%,
      #f54f67 85%,
      #ff8d9e 92%
    );
    cursor: pointer;
    color: white;
    font-size: 33px;
    line-height: 1;
    animation: quickPlayButtonEffect 5s infinite linear;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    padding-bottom: 4px;
  }
  @keyframes quickPlayButtonEffect {
    100% {
      background-position-x: -200%;
    }
  }

  /* -- Header Section CSS End -- */
  #draggable {
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    font-size: 2rem;
    z-index: 10000;
    color: black;
    border-radius: 1rem;
    user-select: none;
    flex-direction: column;
    max-width: 600px;
  }
</style>
